import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import '../../css/Home.scss';
import '../../css/newsCarousal.scss';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { baseUrl, loginUrl, getLoginURL } from '../../lib/constants';
import { StateContext } from '../../store/state/contexts';
import { Auth } from '../../api/Auth';
import { AssessmentsHistoryWrapper } from '../assessmentHistory/AssessmentsHistoryWrapper';
import { ClientAccessId } from '../popups/accessCode/ClientAccessId';
import { logEvent } from '../../index';

const qs = require('qs');
const store = require('store');

// import "../css/animate.scss";

function Header(props) {
  const { state, dispatch } = useContext(StateContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [lang, setLang] = useState(store.get('lang') ? store.get('lang') : 'en');

  const [showClientAccessPopUp, setShowClientAccessPopUp] = useState(false);
  const [hasClient, setHasClient] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    auth: { isLoggedIn, email },
  } = state;
  const {
    t, i18n, customWidth, customMaxWidth, education_id, fromStartAssessment,
  } = props;

  const checkClientID = async () => {
    try {
      const res = await fetch(`${baseUrl}/user/accesscode`, {
        credentials: 'include',
        mode: 'cors',
        method: 'GET',
      });
      const assementCode = await res.json();
      if (!assementCode || (typeof assementCode === 'object' && Object.keys(assementCode).length === 0)) {
        logEvent('checkClientID /user/accesscode successful');
        setHasClient(false);
      } else {
        setHasClient(true);
        logEvent('checkClientID /user/accesscode failed');
      }
    } catch (err) {
      setHasClient(false);
      console.log(err);
      logEvent('checkClientID /user/accesscode failed');
    }
  };

  const checkAssessmentInProgress = async () => {
    setLoading(true);
    // alert('set')
    fetch(`${baseUrl}/assessment/inprogress`, {
      credentials: 'include',
      mode: 'cors',
      method: 'GET',
    }).then((res) => res.json())
      .then((res) => {
        if (res == '') {
          // setLoading(false)
          setInProgress(false);
          logEvent('checkAssessmentInProgress /assessment/inprogress failed');
        } else {
          // setLoading(false)
          setInProgress(true);
          logEvent('checkAssessmentInProgress /assessment/inprogress successful');
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    checkClientID();
    checkAssessmentInProgress();
  }, []);

  const getLoginURL = (nonce) => `https://mensanteid.b2clogin.com/mensanteid.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_fbn_signup_signin&client_id=ffbaada4-c315-47b7-b500-e1b96eccaaaa&nonce=${`${encodeURIComponent(nonce)
    }&redirect_uri=${encodeURIComponent(`${baseUrl}/callback`)}`
    }&scope=openid&response_type=id_token&prompt=login&response_mode=form_post`;

  const getNonce = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const nonce = urlParams.get('code');
    if (nonce != null) return nonce;
    return 'defaultNonce';
  };

  const logOut = (e) => {
    e.preventDefault();
    try {
      Auth.logout().then((res) => {
        dispatch({
          type: 'HANDLE_AUTH_LOGOUT',
        });
        window.location.replace('/');
      });
    } catch (err) {
      console.log({ err });
    }
  };
  const handleMenuClick = (e) => {
    $('.only-show-in-mobile').fadeToggle();
    $('.only-show-in-mobile').toggleClass('sidebar-show');
    $('.header-backdrop').toggleClass('body-overlay');
    $('#open-mobile-menu').toggleClass('active');
  };

  const handleClosePopup = () => {
    setShowClientAccessPopUp(false);
  };

  const headerStyle = {
    top: '3%',
    width: customWidth || '100%',
  };

  if (customMaxWidth) headerStyle.maxWidth = customMaxWidth;
  return (
    <>
      <ClientAccessId
        i18n={i18n}
        t={t}
        show={showClientAccessPopUp}
        handleClose={handleClosePopup}
        onHide={handleClosePopup}
      />
      <div className="nav homeNav customHeader assessmentNav wow fadeInUp" style={headerStyle}>
        <div className="header-backdrop" onClick={handleMenuClick} />
        <ul className="logo-part equitable-part">
          <li className="nav-item d-flex align-items-center header-logo">
            <Link to="/">
              <div className="logo-size">
                <img
                  src={require('../../assets/images/FeelingBetterNowLogo.svg').default}
                  alt="Feeling Better Now"
                  style={{ height: 40 }}
                  className="align-item-center img-fluid img-responsive logo equitable-part-fbn"
                />
                <img
                  src={require(`../../assets/images/${lang === 'en' ? 'Equitable.png' : 'Equitable-fr.png'}`)}
                  alt="Feeling Better Now"
                  style={{ height: 40 }}
                  className="align-item-center img-fluid img-responsive logo ml-2 mt-1 equitable-part-logo"
                />
              </div>
            </Link>
          </li>
        </ul>
        {education_id ? (
          <ul className="d-flex link-part">
            <li className="nav-item d-flex align-items-center justify-content-end">
              <Link to={`/Education/${education_id}`} className="nav-link">
                {t('navbar.education')}
              </Link>
            </li>
          </ul>
        ) : <></>}
        {/* <ul className="d-flex link-part">
            {education_id ? (
              <li className="nav-item d-flex align-items-center justify-content-end">
                <Link to={`/Education/${education_id}`} className="nav-link">
                {t("navbar.education")}
                </Link>
              </li>)
            :<></> }
          </ul>  */}
        <ul className="d-flex link-part">
          {isLoggedIn ? (
            <li
              className="nav-item d-flex align-items-center justify-content-end"
              data-toggle="modal"
              data-target="#assessmentHistoryModal"
              role="button"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              {t('navbar.assessment')}
            </li>
          ) : null}

          {education_id ? (
            <li className="nav-item d-flex align-items-center justify-content-end">
              <Link to={`/Education/${education_id}`} className="nav-link">
                {t('navbar.education')}
              </Link>
            </li>
          ) : null}

          {/* <li className="nav-item d-flex align-items-center justify-content-end">
          <Link to="/profile" className="nav-link">
            {t("navbar.profile")}
          </Link>
        </li> */}
          <li
            className="nav-item d-flex align-items-center justify-content-end"
            tabIndex="0"
          >
            <div
              className="nav-link"
              onClick={() => {
                i18n.changeLanguage(lang === 'en' ? 'fr' : 'en');
                setLang(lang === 'en' ? 'fr' : 'en');
                store.set('lang', lang === 'en' ? 'fr' : 'en');
              }}
              style={{ boxShadow: 'none' }}
            >
              {lang ? (lang === 'en' ? 'French' : 'English') : 'English'}
            </div>
          </li>

          <button
            className="navbar-toggler "
            type="button"
            id="open-mobile-menu"
            onClick={handleMenuClick}
          >
            <span />
            <span />
            <span />
          </button>
          <div className="only-show-in-mobile">
            <div className="nav-header-link">
              <div className="nav-title custom-sidebarHeader">
                <div className="logo-part">
                  <img
                    src={require('../../assets/images/FeelingBetterNowLogo.svg').default}
                    alt="Feeling Better Now"
                    style={{ height: 33 }}
                    className="align-item-center img-fluid img-responsive logo f-logo"
                  />
                </div>
                <div className="mobile-close-icon d-flex justify-content-end" onClick={handleMenuClick}>
                  <img src={require('../../assets/images/mobile-close.svg').default} className="close" />
                </div>
                {/* <div className="nav-header-part">

                  <div className="coins-part d-flex">
                    <img src={require("../../assets/images/header-coins.svg")} className="coin" />
                    <div className="coins-content">
                      <p>{t("commonHeader.myCoin")}</p>
                      <strong>C 1600</strong>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <ul>
              <li>
                <Link to="/profile" className="nav-link">
                  {t('navbar.profile')}
                </Link>
              </li>
              {isLoggedIn ? (
                <li>
                  <a
                    className="nav-link"
                    data-toggle="modal"
                    data-target="#assessmentHistoryModal"
                    role="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setModalOpen(true);
                    }}
                  >
                    {t('navbar.assessment')}
                  </a>
                </li>
              ) : null}

              {education_id ? (
                <li className="nav-item d-flex align-items-center justify-content-end">
                  <Link to={`/Education/${education_id}`} className="nav-link">
                    {t('navbar.education')}
                  </Link>
                </li>
              ) : null}

              {/* <li className="nav-item d-flex align-items-center justify-content-end">
                <Link className="nav-link"> My Achievement</Link>
              </li> */}
              {/* <li
                className="nav-item d-flex align-items-center justify-content-end"
                tabIndex="0"
              >
                <a
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    i18n.changeLanguage(lang === "en" ? "fr" : "en")
                    setLang(lang === "en" ? "fr" : "en")
                    store.set("lang", lang === "en" ? "fr" : "en")
                  }}
                  style={{ boxShadow: "none" }}
                >
                  {lang ? (lang === "en" ? "French" : "English") : "English"}
                </a>
              </li> */}
              <li className="nav-item d-flex align-items-center justify-content-end">
                <a onClick={logOut} className="nav-link">Logout</a>
              </li>
            </ul>
          </div>
        </ul>
        <ul className="logout-part">
          {/* <li className="nav-item d-flex align-items-center justify-content-end notification" tabIndex="0">
            <img src={require("../../assets/images/header-n-bell.svg")} />
          </li> */}
          <li className="nav-item d-flex align-items-center justify-content-end name" data-toggle="dropdown" aria-expanded="false" tabIndex="0">
            <div className="name-round">
              <p className="name">{(email && email[0] + email[1]) || 'FBN'}</p>
            </div>
          </li>
          <li className="nav-item d-flex align-items-center justify-content-end" tabIndex="0">
            <div className="dropdown symptom  custom-header-dropdown">
              <a className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" style={{ width: '100%' }}>
                {/* email */}
                <i className="fas fa-chevron-down ml-0" />
              </a>
              <div className="dropdown-menu custom-menu">
                <ul>
                  {/* <li>
                    <div className="coins-part d-flex">
                      <img src={require("../../assets/images/header-coins.svg")} className="coin" />
                      <div className="coins-content">
                        <p>{t("commonHeader.myCoin")}</p>
                        <strong>C 1600</strong>
                      </div>
                    </div>
                  </li> */}
                  {/* <Link to={`/goal`}>
                    <li>
                      {t("summaryPage.cardSection.myplanGoals")}
                    </li>
                  </Link> */}
                  {/* {education_id ? (
                    <li className="">
                      <Link to={`/Education/${education_id}`} className="">
                        {t("navbar.education")}
                      </Link>
                    </li>
                  ) : null} */}
                  {/* <a
                    onClick={(e) => {
                      e.preventDefault();
                      i18n.changeLanguage(lang === "en" ? "fr" : "en")
                      setLang(lang === "en" ? "fr" : "en")
                      store.set("lang", lang === "en" ? "fr" : "en")
                    }}
                    style={{ boxShadow: "none" }}
                  >
                    <li>
                      {lang ? (lang === "en" ? "French" : "English") : "English"}
                    </li>
                  </a> */}
                  {/* <li className="active">My Achivement</li> */}
                  <Link to="/profile">
                    <li>
                      {t('navbar.profile')}
                    </li>
                  </Link>
                  <a onClick={logOut}>
                    <li>
                      Logout
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <AssessmentsHistoryWrapper
        open={modalOpen}
        toggle={() => {
          setModalOpen(!modalOpen);
        }}
        close={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
}

export default withTranslation()(Header);
