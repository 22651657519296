/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { baseUrl } from '../../lib/constants';
// import { Navbar } from "../components/Navbar";
import '../../css/main.css';
import '../../css/pap.scss';
import '../../css/Goal.scss';
import {
  EH2,
  // EBlockWrapper,
  EMainBlock,
  // ETopMainCaption,
} from '../summary/Summary';
import Footer from '../../components/footer/Footer';
import Header from '../../components/navbar/Header';
import EditIcon from '../../assets/images/edit-icon.svg';
import AddChipsIcon from '../../assets/images/add-chips-btn.svg';
import MinusIcon from '../../assets/images/minus.svg';
import Emark from '../../assets/images/emark.png'
import Menu from '../../assets/images/menu.png'
import DeleteIcon from '../../assets/images/delete-icon.svg';
import BgImg from '../../assets/images/top-bg-bottom-gradient.png';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorScreen } from '../../components/error/ErrorScreen';
import ReactSlidingSidePanel from '../../components/bottom-slider/ReactSlidingSidePanel';
import NoGoals from './NoGoals';
import Axios from 'axios';
import classnames from 'classnames';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import Joyride, { STATUS } from 'react-joyride'
import { StateContext } from '../../store/state/contexts';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Action from './Action';
import AddBtnIcon from '../../assets/images/plush-white-btn.svg'
import { useGoogleLogin } from '@react-oauth/google';
import { fetchCoins } from '../../components/form/Forms';
import { validateToken, fetchGoogleAccessToken, GOOGLE_CALENDAR_SCOPES } from '../../utils/googleCalendar';

const options = {
  withCredentials: true,
  mode: "cors",
};

const TASK_DEFAULT_DAYS = 30

const axios = Axios.create(options);
const axiosGoogle = Axios;
const EBlockWrapper = styled.div`
   font-family: "CircularStdBook";
   margin: 10px auto 0 auto;
   max-width: 83em;
   padding: 0 1em;
   position: relative;
   z-index: 1.5;
   @media (max-width: 1440px) {
    //  max-width: 90em;
   }
    @media (max-width: 1024px) {
      margin: 1em auto 0 auto;
    }
    @media (max-width: 991px) {
      margin: 1em auto 0 auto;
    }
    @media (max-width: 768px) {
      margin: 0em auto 0 auto;
    }
 `;
export const EWrapper = styled.div`
   // max-width: 74em;
   margin: 0 auto;
   padding-bottom: 5em;
 
   @media (max-width: 1080px) {
     font-size: 0.7em;
   }
 
   @media (max-width: 1180px) {
     font-size: 0.8em;
   }
 
   @media (max-width: 1280px) {
     font-size: 0.9em;
   }
 
   @media (min-width: 1480px) {
     font-size: 1.1em;
   }
 `;

export const ELoadingWrapper = styled.div`
   width: 100%;
   height: 200px;
   display: flex;
   align-items: center;
   justify-content: center;
   > div {
     transform: scale(5);
   }
   font-family: "CircularStdBook";
 `;

export const ESymptomsGrid = styled.ul`
   display: grid;
   grid-column-gap: 1em;
   grid-row-gap: 1.5em;
   border-radius: 1em !important;
   background-color: #e1f4f4;
   padding: 1.8em 1.4em;
   font-family: "CircularStdBook";
   grid-template-columns: 9fr 10fr;
 
   i {
     font-size: 1.3em;
     margin-right: 0.5em;
   }
 
   @media (max-width: 640px) {
     grid-template-columns: 1fr;
   }
 `;

export const EList = styled.div`
   display: grid;
   grid-row-gap: 1.5em;
   font-family: "CircularStdBook";
 
   > div {
     display: grid;
     grid-template-columns: min-content 1fr;
     grid-column-gap: 1em;
   }
 
   i {
     font-size: 1.3em;
     margin-right: 0.5em;
   }
 `;

export const EMedListWrapper = styled.div`
   border: 1px solid #eaeaea;
   background-color: #fafafa;
   margin: 1em 2.3em 0 2.3em;
   border-radius: 0.5em;
 `;

export const EP1 = styled.p`
   font-size: 1.1em;
   font-family: "CircularStdBook";
   line-height: 1.8;
   margin: 0;
   color: ${(props) => (props.color ? props.color : '#151852')};
 `;

export const EP2 = styled.p`
   font-size: 0.87em;
   font-weight: 500;
   font-family: "CircularStdBook";
   margin: 0;
 `;

export const EHeaderWrapper = styled.div`
   min-height: 5em;
   display: grid;
   grid-template-columns: 18fr 10fr;
   grid-column-gap: 2em;
   background-color: #e1f4f4;
   align-items: center;
   padding: 0 2.5em;
   color: #151852;
   font-family: "CircularStdBook";
 
   .info {
     font-size: 1.44em;
     display: grid;
     grid-template-columns: 1fr max-content;
   }
 `;

export const ETipsGrid = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-column-gap: 1.8em;
   grid-row-gap: 2.3em;
   font-family: "CircularStdBook";
   margin: 2.6em 2.3em 0 2.3em;
 
   > div {
     border: 1px solid #d7d7d7;
     border-radius: 0.5em;
     padding: 2em;
     display: grid;
     grid-template-columns: max-content 1fr;
     grid-column-gap: 1.4em;
   }
 
   @media (max-width: 1400px) {
     grid-template-columns: 1fr 1fr;
   }
 
   @media (max-width: 900px) {
     grid-template-columns: 1fr;
   }
 `;

export const ETipsGridImage = styled.div`
   padding: 1.4em;
   background-color: #ebebeb;
   border-radius: 100%;
   img {
     width: 2.2em;
     height: 2.2em;
     object-fit: contain;
   }
 `;

export const EPapCardRoot = styled.div`
   border-radius: 0.8em 0.8em 0.8em 0.8em / 0.4em 0.4em 0.4em 0.4em;
   box-shadow: 0px 4px 21px rgba(55, 62, 61, 0.23);
   width: 100%;
   background-color: #fff;
   overflow: hidden;
   font-family: "CircularStdBook";
   padding-bottom: 5em;
 `;

export const EPapDisorderCaption = styled.h2`
   font-size: 2em;
   font-weight: 600;
   color: #151852;
   font-family: "CircularStdBook";
   margin: 0;
 `;

export const EDescriptionAndSymptoms = styled.div`
   display: grid;
   grid-template-columns: 14fr 7.5fr;
   grid-column-gap: 2em;
   margin: 2.3em 2.3em 0 2.3em;
 `;

export const EMedicationAndClinicalSteps = styled.div`
   display: grid;
   grid-template-columns: 14fr 7.5fr;
   grid-column-gap: 2em;
   margin: 2.3em 2.3em 0 2.3em;
 `;

export const EH2Orange = styled.h2`
   color: #f3763e;
   font-size: 1.5em;
   margin: 0;
 `;

export const MedicationTableHeder = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   margin-left: 1.3em;
   grid-column-gap: 1em;
 
   p {
     color: #8d8ea5;
   }
 `;

export const MedicationTableWrapper = styled.div`
   display: flex;
   flex-direction: column;
   border-radius: 1em;
   overflow: hidden;
 
   > div:nth-of-type(odd) {
     background-color: #e1f4f4;
   }
   > div:nth-of-type(even) {
     background-color: #f8fcff;
   }
 `;

export const MedicationTable = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   grid-column-gap: 1em;
   padding: 1.08em 0 1.08em 1.3em;
   p {
     font-weight: 400 !important;
   }
 `;

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",
  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: "",
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  }
});

const err = {
  borderColor: '#34ca8f', borderStyle: 'solid', borderWidth: 2, borderRadius: 6
}

const colourStyles = (error) => ({
  container: (styles) => ({ ...styles, ...(error ? err : {}) }),
  menu: (styles) => ({ ...styles, zIndex: 23 }),
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({ ...styles, backgroundColor: "white", position: 'relative', borderColor: isSelected ? '#33ca8f' : '#dddfe3', ':hover': { borderColor: '#dddfe3' }, boxShadow: isSelected ? undefined : undefined }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      zIndex: 9,
      fontSize: 14, color: '#505050',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#e7f9f7"
          : isFocused
            ? "#e7f9f7"
            : undefined,
      // color: isDisabled
      //   ? '#ccc'
      //   : isSelected
      //   ? chroma.contrast(color, "white") > 2
      //     ? "white"
      //     : "black"
      //   : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#dddfe3"
            : "#dddfe3"
          : undefined,
      },
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, fontSize: 14, color: '#979797' }),
  singleValue: (styles, { data }) => ({ ...styles, marginRight: 70 }),
});

const Goals = ({ match, t, i18n }) => (
  <View
    i18n={i18n}
    t={t}
  />
);

export default withTranslation()(Goals);

const ControlComponent = (props) => (
  <>
    <button {...props} onBlur={props.setMenuIsOpen} disabled={props.updateGoalLoading} onClick={props.setMenuIsOpen} className="chips-btn" data-tut="add-symptom-btn">{props.updateGoalLoading ? <i className="fas fa-circle-notch fa-spin"></i> : <img src={AddChipsIcon} className="chips-add" />}</button>
    <div className={`${props.menuIsOpen ? "chips-btn-overlay" : "close"}`} onClick={props.setMenuIsOpen}></div>
  </>
);

const CustomMenu = (props) => (
  <div style={{ minWidth: 200 }}>
    <components.Menu {...props} style={{ zIndex: 2 }} />
  </div>
);

export const View = ({
  t,
  i18n,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [addGoalLoading, setAddGoalLoading] = useState(false);
  const [updateGoalLoading, setUpdateGoalLoading] = useState(false);
  const [getEventsLoading, setGetEventsLoading] = useState(false);

  const [error, setError] = useState(null);

  const [goals, setGoals] = useState([]);
  const [allGoals, setAllGoals] = useState([]);
  const [filteredGoals, setfilteredGoals] = useState([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState('');
  const [symptoms, setSymptoms] = useState([]);
  const [allSymptoms, setAllSymptoms] = useState([]);
  const [rawSymptoms, setrawSymptoms] = useState([]);
  const [rawAllSymptoms, setrawAllSymptoms] = useState([]);
  const [symptomTitle, setSymptomTitle] = useState('');
  const formRef = useRef();
  const symptomsRef = useRef();
  const addGoalInput = useRef();
  const [goalName, setGoalName] = useState('');

  const planFormRef = useRef();
  const [planName, setPlanName] = useState('');

  const [editDescription, setEditDescription] = useState({});
  const [goalDescriptions, setGoalDescriptions] = useState({});
  const [editTitle, setEditTitle] = useState({});
  const [goalTitles, setGoalTitles] = useState({});

  const [taskTitle, settaskTitle] = useState({});
  const [taskDueDate, setTaskDueDate] = useState({});
  const [taskDateToggle, setTaskDateToggle] = useState({});

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [addNewPlan, setAddNewPlan] = useState({});


  const [LOAD_INCENTIVES, SET_LOAD_INCENTIVES] = useState(false);
  const [LOAD_GOOGLE_CALENDAR, SET_LOAD_GOOGLE_CALENDAR] = useState(false);

  const [updateInprogress, setUpdateInprogress] = useState(false);
  // const [tooltip, setTooltip] = useState(false);
  const [checked, setChecked] = React.useState(false);
  // tooltip part

  const [run, setRun] = useState(false);
  const [tourOpen, setTourOpen] = useState(false);
  const [anchor, setAnchor] = useState('');
  const { state, dispatch } = useContext(StateContext);
  const [value, onChange] = useState('10:00');

  const [selectedGoal, setSelectedGoal] = useState();
  const [isActive, setIsActive] = useState(true);

  const [coinsLoading, setCoinsLoading] = useState(false)
  const [coinData, setCoinData] = useState(false)

  const showTooltipOnhover = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
    console.log("hello");
  };

  const [googleLoggedin, setGoogleLoggedin] = useState(false);
  const [allEvents, setAllEvents] = useState([])

  // const addTooltip = event => {
  //   setTooltip(current => !current);
  // }

  const getGoals = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/plans-goals/goals`);
      console.log(response);
      const goalsArray = [];
      for (const key in response.data) {
        // if (!response.data[key].deleted) {
        goalsArray.push({ ...response.data[key], id: key });
        // }
      }
      setGoals(goalsArray);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setLoading(false);
    }
  };

  const getAllGoals = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/plans-goals/goals-all`);
      console.log(response);
      const goalsArray = [];
      for (const key in response.data) {
        // if (!response.data[key].deleted) {
        goalsArray.push({ ...response.data[key], id: key });
        // }
      }
      setAllGoals(goalsArray);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setLoading(false);
    }
  };

  const getSymptoms = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/plans-goals/symptoms`);
      console.log(response);
      const symptomsArray = [];
      for (const key in response.data) {
        symptomsArray.push({ ...response.data[key], id: key });
      }
      setSymptoms(symptomsArray);
      setrawSymptoms(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setLoading(false);
    }
  };

  const getAllSymptoms = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/plans-goals/symptoms-all`);
      const symptomsArray = [];
      for (const key in response.data) {
        symptomsArray.push({ ...response.data[key], id: key });
      }
      setAllSymptoms(symptomsArray);
      setrawAllSymptoms(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setLoading(false);
    }
  };

  const addSymptoms = async () => {
    if (symptomTitle) {

      try {
        setLoading(true);
        const response = await axios.post(`${baseUrl}/plans-goals/symptoms`, {
          title: {
            en: symptomTitle
          }
        });
        console.log(response);
        const symptomsArray = [];
        for (const key in response.data) {
          symptomsArray.push({ ...response.data[key], id: key });
        }
        setSymptoms(symptomsArray);
        setrawSymptoms(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setLoading(false);
      }
    }
  };

  const addGoal = async (goalName) => {
    // e.preventDefault();
    if (goalName && !addGoalLoading) {
      try {
        setLoading(true)
        setAddGoalLoading(true)
        const response = await axios.post(`${baseUrl}/plans-goals/goal`, {
          title: {
            en: goalName,
          },
        });
        // const goalsArray = [];
        // for (const key in response.data) {
        //   goalsArray.push({ ...response.data[key], id: key });
        // }
        let goal = response.data.data
        let [key] = Object.keys(goal)
        setGoals([goal[key], ...goals]);
        setAllGoals([goal[key], ...allGoals])
        setGoalName('');
        setAddGoalLoading(false);
        setLoading(false)
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setAddGoalLoading(false);
        setLoading(false)
      }
    }
  };
  const addPlan = async (e, goal, index) => {
    e.preventDefault();
    if (planName && !updateGoalLoading) {
      setUpdateGoalLoading(true);
      try {
        const response = await axios.post(`${baseUrl}/plans-goals/plan/${goal.id}`, {
          title: {
            en: planName,
          },
        });

        setGoals(goals.map(gol => gol.id === goal.id ? { ...goal, plans: { ...goal.plans, ...response.data.data } } : gol));
        setPlanName('');
        setAddNewPlan({ ...addNewPlan, [index]: !addNewPlan[index] });
        setUpdateGoalLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
      }
    }
  };
  const addSymptomToGoal = async (symptom, goal) => {
    if (!updateGoalLoading) {
      const newGoal = { ...goal };
      newGoal.symptoms = newGoal.symptoms ? Object.keys(newGoal.symptoms) : [];

      newGoal.symptoms.push(symptom.value);
      try {
        const response = await updateGoal(newGoal);
        if (response) {
          setGoalName('');
        }
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
      }
    }
  };
  const updateDescription = async (index, goal) => {
    if (goalDescriptions[index]) {
      try {
        const newGoal = { ...goal };
        newGoal.symptoms = newGoal.symptoms ? Object.keys(newGoal.symptoms) : [];
        newGoal.description = {
          en: goalDescriptions[index],
          fr: goalDescriptions[index],
        };
        const response = await updateGoal(newGoal);
        if (response) {
          setEditDescription({ ...editDescription, [index]: false });
        }
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
      }
    }
  };

  const updateGoalTitle = async (index, goal) => {
    if (goalTitles[index]) {
      try {
        const newGoal = { ...goal }
        newGoal.symptoms = newGoal.symptoms ? Object.keys(newGoal.symptoms) : [];
        newGoal.title = {
          en: goalTitles[index],
          fr: goalTitles[index],
        };
        const response = await updateGoal(newGoal);
        if (response) {
          setEditTitle({ ...editTitle, [index]: false });
        }
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
      }
    }
  }

  const deletePlan = async (goalId, planId) => {
    if (window.confirm('Are you sure you want to delete this plan?')) {
      try {
        setUpdateGoalLoading(true);
        const response = await axios.delete(`${baseUrl}/plans-goals/goal/${goalId}/plan/${planId}`);

        let currentGoal = goals.find(gol => gol.id === goalId)
        let plans = currentGoal.plans
        delete plans[planId]
        setGoals(goals.map(gol => gol.id === goalId ? ({ ...gol, plans }) : gol));
        setUpdateGoalLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
      }
    }
  }

  const deleteGoal = async (goalId) => {
    if (window.confirm('Are you sure you want to delete this action?')) {
      try {
        setUpdateGoalLoading(true);
        const response = await axios.delete(`${baseUrl}/plans-goals/goal/${goalId}`);

        setGoals(goals.filter(gol => gol.id !== goalId));
        setUpdateGoalLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
      }
    }
  }

  const handleSuccess = async (tokenResponse) => {
    const fetchResponse = await fetchGoogleAccessToken(tokenResponse)
    if (fetchResponse == true) {
      const validateResponse = await validateToken()
      if (validateResponse == true) {
        setGoogleLoggedin(true)
        console.log("Authorized")
        getAllEventsIn()
      }
    }
  }

  const googleLogIn = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: error => console.log("login error :- ", error),
    flow: 'auth-code',
    ux_mode: 'popup',
    scope: GOOGLE_CALENDAR_SCOPES
  })

  useEffect(() => {
    if (selectedGoal && !goals.find(goal => goal.id === selectedGoal.value)) {
      setSelectedGoal(null)
    }
  }, [goals])

  const deleteTask = async (goalId, planKey, taskIndex) => {
    try {
      if (!updateGoalLoading) { setUpdateGoalLoading(true); }
      await axios.delete(`${baseUrl}/plans-goals/goal/${goalId}/plan/${planKey}/task/${taskIndex}`);
      setGoals(goals.map(gol => {
        if (gol.id === goalId) {
          gol.plans[planKey].tasks = gol.plans[planKey].tasks.filter((task, index) => index !== taskIndex)
        }
        return gol
      }));
      setUpdateGoalLoading(false);
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setUpdateGoalLoading(false);
    }
  };

  const addTask = async (e, goalId, planId, goal) => {
    e.preventDefault();
    if (taskTitle[goalId] && taskTitle[goalId][planId] && !updateGoalLoading) {
      try {
        setUpdateGoalLoading(true);
        const response = await axios.post(`${baseUrl}/plans-goals/goal/${goal.id}/plan/${planId}/task`, {
          title: {
            en: taskTitle[goalId][planId],
          },
          created_at: new Date,
          mode: 'scheduleable',
        });
        setGoals(goals.map(gol => {
          if (gol.id === goal.id) {
            gol.plans[planId].tasks = response.data.data
          }
          return gol
        }));
        settaskTitle({ ...taskTitle, [goalId]: { ...taskTitle[goalId], [planId]: '' } });
        setUpdateGoalLoading(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
      }
    } else {
      toast.error('Please enter task title')
    }
  };

  const updateTaskStatus = async (goalIndex, planId, taskIndex, status) => {
    // console.log('taks details', goals[goalIndex].plans[planId].tasks[taskIndex], goalIndex, planId, taskIndex) //goals[goalIndex][planId][taskIndex]
    if (
      goals[goalIndex]
      && goals[goalIndex].plans
      && goals[goalIndex].plans[planId]
      && goals[goalIndex].plans[planId].tasks
      && goals[goalIndex].plans[planId].tasks[taskIndex]
      && !updateInprogress
      && !updateGoalLoading
    ) {
      setUpdateInprogress(true);
      const task = goals[goalIndex].plans[planId].tasks[taskIndex];
      if (task.status === 0) { task.status = 1 } else if (task.status === 1) { task.status = 2 } else { task.status = 0 }
      goals[goalIndex].plans[planId].tasks[taskIndex] = task;
      setGoals([...goals]);
      try {
        setUpdateGoalLoading(true);
        const goal = { ...goals[goalIndex] };
        goal.symptoms = goal.symptoms ? Object.keys(goal.symptoms) : [];
        await updateGoal(goal);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      }
    }
  };

  const updateTaskType = async (goalIndex, planId, taskIndex, type) => {
    if (
      goals[goalIndex]
      && goals[goalIndex].plans
      && goals[goalIndex].plans[planId]
      && goals[goalIndex].plans[planId].tasks
      && goals[goalIndex].plans[planId].tasks[taskIndex]
      && !updateInprogress
      && !updateGoalLoading
    ) {
      setUpdateInprogress(true);
      const task = goals[goalIndex].plans[planId].tasks[taskIndex];
      task.mode = type || (task.mode !== 'habit' ? 'habit' : 'scheduleable');
      task.days = task.days || TASK_DEFAULT_DAYS;

      goals[goalIndex].plans[planId].tasks[taskIndex] = task;
      setGoals([...goals]);
      try {
        if (!updateGoalLoading) { setUpdateGoalLoading(true); }
        const goal = { ...goals[goalIndex] };
        goal.symptoms = goal.symptoms ? Object.keys(goal.symptoms) : [];
        await updateGoal(goal);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      }
    }
  };

  const updateTaskEventId = async (goalIndex, planId, taskIndex, eventId) => {
    if (
      goals[goalIndex]
      && goals[goalIndex].plans
      && goals[goalIndex].plans[planId]
      && goals[goalIndex].plans[planId].tasks
      && goals[goalIndex].plans[planId].tasks[taskIndex]
      && !updateInprogress
      && !updateGoalLoading
    ) {
      setUpdateInprogress(true);
      const task = goals[goalIndex].plans[planId].tasks[taskIndex];

      task.eventId = eventId

      goals[goalIndex].plans[planId].tasks[taskIndex] = task;
      setGoals([...goals]);
      try {
        setUpdateGoalLoading(true);
        const goal = { ...goals[goalIndex] };
        goal.symptoms = goal.symptoms ? Object.keys(goal.symptoms) : [];
        await updateGoal(goal);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      }
    }
  };

  const updateTaskDays = async (goalIndex, planId, taskIndex, type, inc) => {
    if (
      goals[goalIndex]
      && goals[goalIndex].plans
      && goals[goalIndex].plans[planId]
      && goals[goalIndex].plans[planId].tasks
      && goals[goalIndex].plans[planId].tasks[taskIndex]
      && !updateInprogress
      && !updateGoalLoading
    ) {
      setUpdateInprogress(true);
      const task = goals[goalIndex].plans[planId].tasks[taskIndex];
      // task.mode = type || (task.mode !== 'habit' ? 'habit' : 'scheduleable');
      // task.days = task.days || TASK_DEFAULT_DAYS;
      if (task.mode !== 'habit') {
        alert('Selected task is not Habit')
        return
      }
      if (type === 'progress') {
        if (inc) {
          task.process_days = task.process_days ? ++task.process_days : 1
        } else {
          task.process_days = task.process_days ? --task.process_days : 0
        }
      } else {
        if (inc) {
          task.days = task.days ? ++task.days : 1
        } else {
          task.days = task.days ? --task.days : 0
        }
      }

      goals[goalIndex].plans[planId].tasks[taskIndex] = task;
      setGoals([...goals]);
      try {
        setUpdateGoalLoading(true);
        const goal = { ...goals[goalIndex] };
        goal.symptoms = goal.symptoms ? Object.keys(goal.symptoms) : [];
        await updateGoal(goal);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      }
    }
  };

  const getTaskDueDate = (goalIndex, planId, taskIndex) => {
    if (taskDueDate[goalIndex] && taskDueDate[goalIndex][planId] && taskDueDate[goalIndex][planId][taskIndex]) {
      return taskDueDate[goalIndex][planId][taskIndex];
    } else {
      return goals[goalIndex].plans[planId].tasks[taskIndex].due_date && moment(goals[goalIndex].plans[planId].tasks[taskIndex].due_date).toDate();
    }
  }

  const onDateChange = async (date, index, planKey, taskIndex) => {
    if (
      goals[index]
      && goals[index].plans
      && goals[index].plans[planKey]
      && goals[index].plans[planKey].tasks
      && goals[index].plans[planKey].tasks[taskIndex]
      && !updateInprogress
    ) {
      setUpdateInprogress(true);
      const task = {
        ...taskDueDate,
        [index]: {
          ...(taskDueDate[index] || {}),
          [planKey]: {
            ...(taskDueDate[index] ? taskDueDate[index][planKey] : {}),
            [taskIndex]: date,
          },
        },
      };
      setTaskDueDate({ ...task });
      goals[index].plans[planKey].tasks[taskIndex].due_date = date;
      setGoals([...goals]);
      try {
        if (!updateGoalLoading) { setUpdateGoalLoading(true); }
        const goal = { ...goals[index] };
        goal.symptoms = goal.symptoms ? Object.keys(goal.symptoms) : [];
        await updateGoal(goal);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      }
    }
  }

  async function updateGoal(goal) {
    try {
      setUpdateGoalLoading(true);
      const response = await axios.put(`${baseUrl}/plans-goals/goal/${goal.id}`, goal);
      // const goalsArray = [];
      // for (const key in response.data) {
      //   goalsArray.push({ ...response.data[key], id: key });
      // }
      let gol = response.data.data
      // let [key] = Object.keys(gol)
      setGoals([gol, ...goals]);
      setUpdateGoalLoading(false);
      return true;
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setUpdateGoalLoading(false);
      return false;
    }
  }

  const onInputChange = (options, { action }) => {
    if (action === 'menu-close') {
      setMenuIsOpen(false);
    }
  };

  const toggleDates = (index, planKey, taskIndex) => {
    let flag = true;
    if (taskDateToggle[index] && taskDateToggle[index][planKey] && taskDateToggle[index][planKey][taskIndex]) {
      flag = false;
    }
    taskDateToggle[index] = {
      ...((taskDateToggle[index]) || {}),
      [planKey]: {
        ...((taskDateToggle[index] && taskDateToggle[index][planKey])),
        [taskIndex]: flag,
      },
    };
    setTaskDateToggle({ ...taskDateToggle });
  };

  useEffect(() => {
    const tx = document.getElementsByTagName('textarea');
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute('style', `height:${tx[i].scrollHeight}px;overflow:hidden;`);
      tx[i].addEventListener('input', OnInput, false);
    }

    function OnInput() {
      this.style.height = 'auto';
      this.style.height = `${this.scrollHeight}px`;
    }
  }, [editDescription]);

  const toggleTitleInput = (index) => {
    const headingEl = document.getElementById(`goal_title_${index}`);
    let width = headingEl.clientWidth;
    setEditTitle({ ...editTitle, [index]: !editTitle[index] });

    setTimeout(() => {
      const inputEl = document.getElementById(`goal_title_input_${index}`);

      if (inputEl) {
        inputEl.addEventListener('input', resizeInput); // bind the "resizeInput" callback on "input" event
        resizeInput.call(inputEl); // immediately call the function

        function resizeInput() {
          if (this) {
            this.style.width = this.value.length + "ch";
          }
        }
        inputEl.style.width = `${width}px`;
        inputEl.focus();
      }
    }, 100)
  };
  const getTaskTitle = (goalIndex, planId) => {
    if (taskTitle[goalIndex]) {
      return taskTitle[goalIndex][planId];
    }
    return '';
  };

  const startup = async () => {
    await getGoals();
    await getAllGoals()
    await getSymptoms();
    await getAllSymptoms();
    symptomsRef && symptomsRef.current && symptomsRef.current.click();
    try {
      setCoinsLoading(true)
      let coins = await fetchCoins();
      setCoinData(coins)
    } catch (e) {
      console.log(e, ' <=== error while fetching coins')
    } finally {
      setCoinsLoading(false)
    }
  }

  useEffect(() => {
    startup()
  }, []);

  const filterGoals = async (symptomId = false) => {
    if (symptomId) {
      if (selectedSymptoms === symptomId) {
        setSelectedSymptoms('');
      } else {
        setSelectedSymptoms(symptomId);
      }
    }
    setfilteredGoals(goals.filter((goal) => {
      for (let i = 0; i < selectedSymptoms.length; i++) {
        if (goal.symptoms[selectedSymptoms[i]]) {
          return true;
        }
      }
      return false;
    }));
    addGoalInput.current && addGoalInput.current.focus();
  };
  const updateWeekDays = async (index, planKey, taskIndex, day) => {
    if (goals[index]
      && goals[index].plans[planKey]
      && goals[index].plans[planKey].tasks[taskIndex]
      && !updateGoalLoading
    ) {
      if (!goals[index].plans[planKey].tasks[taskIndex].week_days) {
        goals[index].plans[planKey].tasks[taskIndex].week_days = [];
      }
      if (goals[index].plans[planKey].tasks[taskIndex].week_days.includes(day)) {
        goals[index].plans[planKey].tasks[taskIndex].week_days = goals[index].plans[planKey].tasks[taskIndex].week_days.filter((d) => d !== day);
      } else {
        goals[index].plans[planKey].tasks[taskIndex].week_days.push(day);
      }
      setGoals([...goals]);
      try {
        if (!updateGoalLoading) { setUpdateGoalLoading(true); }
        const goal = { ...goals[index] };
        goal.symptoms = goal.symptoms ? Object.keys(goal.symptoms) : [];
        await updateGoal(goal);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setUpdateGoalLoading(false);
        setUpdateInprogress(false);
      }
    }
  };



  const lang = i18n.language;

  const closeTour = () => {
    enableBodyScroll();
    setTourTooltipViewed();
    setTourOpen(false);
  };


  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);


  const anchorSelector = (el) => {
    if (el == anchor || !document.querySelector(el)) return false;
    setAnchor(el);
    let currentEl = document.querySelector(el);
    let rect = currentEl.getBoundingClientRect();
    if (rect.top + rect.height > window.innerHeight / 1.5 || rect.top < 50) {
      document.scrollingElement.scrollTop += rect.top - 50;
    }
  }



  const tourConfig = [

    {
      disableBeacon: true,
      target: '[data-tut="add-symptom-dropdown"]',
      content: t("goalPage.tour.step1"),
      action: () => {

      },
    },
    {
      disableBeacon: true,
      target: '[data-tut="add-action-dropdown"]',
      content: "This dropdown contains actions that will address the symptom currently selected in the symptoms dropdown.  Our recommended actions come with a breakdown of plans and tasks.  You can also add your own.",
      action: () => {

      },
    },
    {
      disableBeacon: true,
      target: '[data-tut="login-alert"]',
      content: "You can upload tasks to your Google calendar account as events.  You need to select a date and time first using the task menu",
      action: () => {

      },
    },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="after-login-alert"]',
    //   content: "Your are login sucessfully",
    //   action: () => {

    //   },
    // },
    {
      disableBeacon: true,
      target: '[data-tut="allocate-coins-btn"]',
      content: "You can win coins by completing  all tasks in a plan.  Those coins can then be used to enter lotteries.  At the beginning of the month you have 2000 coins you can allocate to the plans you want to complete.  If you complete all the tasks in a plan by the end of the month you will earn the all of coins you allocated to it.",
      action: () => {

      },
    },
    {
      disableBeacon: true,
      target: '[data-tut="add-btn"]',
      content: t("goalPage.tour.step2"),
      action: () => {

      },
    },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="edit-btn"]',
    //   content: t("goalPage.tour.step3"),
    //   action: () => {

    //   },
    // },
    {
      disableBeacon: true,
      target: '[data-tut="add-symptom-btn"]',
      content: t("goalPage.tour.step4"),
      action: () => {

      },
    },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="delete-symptom-btn"]',
    //   content: t("goalPage.tour.step5"),
    //   action: () => {

    //   },
    // },
    {
      disableBeacon: true,
      target: '[data-tut="manage-state-part"]',
      content: t("goalPage.tour.step6"),
      action: () => {

      },
    },
    {
      disableBeacon: true,
      target: '[ data-tut="calendar-btn"]',
      content: t("goalPage.tour.step7"),
      action: () => {

      },
    },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="calendar-event-btn"]',
    //   content: 'You can check status of calendar and sync event',
    //   action: () => {

    //   },
    // },
    {
      disableBeacon: true,
      target: '[data-tut="more-btn"]',
      content: t("goalPage.tour.moreBtn"),
      action: () => {

      },
    },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="schedule-more-btn"]',
    //   content: 'You can schedule your task',
    //   action: () => {

    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="recurring-more-btn"]',
    //   content: 'You can recurring  your task',
    //   action: () => {

    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="habit-more-btn"]',
    //   content: 'You can habit your task',
    //   action: () => {

    //   },
    // },

    {
      disableBeacon: true,
      target: '[ data-tut="refresh-btn"]',
      content: t("goalPage.tour.step8"),
      action: () => {

      },
    },
    {
      disableBeacon: true,
      target: '[ data-tut="delete-task-btn"]',
      content: t("goalPage.tour.step9"),
      action: () => {

      },
    },
    {
      disableBeacon: true,
      target: '[ data-tut="add-specific-task"]',
      content: t("goalPage.tour.step10"),
      action: () => {

      },
    },
    // {
    //   disableBeacon: true,
    //   target: '[ data-tut="add-plan-btn"]',
    //   content: 'you can add plan here',
    //   action: () => {

    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="delete-action-btn"]',
    //   content: 'you can delete your jumstart here',
    //   action: () => {

    //   },
    // },

  ];

  const showTourTooltipBool = () => {
    let tourTooltipCount = localStorage.getItem('_FBN_GOALS_TOOLTIP_SHOW_');
    if (tourTooltipCount) {
      tourTooltipCount = parseInt(tourTooltipCount);
      if (tourTooltipCount < 6) {
        return true;

      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  function onDropdownclick(event) {
    event.stopPropagation();
  }

  useEffect(() => {
    if (showTourTooltipBool()) {
      setTourOpen(true);
      setRun(true);
    }
  }, []);

  const getAllEventsIn = async () => {
    try {
      setUpdateGoalLoading(true)
      let response = await axiosGoogle.get(`https://www.googleapis.com/calendar/v3/calendars/primary/events?access_token=${localStorage.getItem('google_access_token')}`)
      console.log(response.data.items)
      setAllEvents(response.data.items)
    } catch (e) {
      console.log(e)
    } finally {
      setUpdateGoalLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('google_access_token')) {
        const response = await validateToken()
        if (response == true) {
          setGoogleLoggedin(true)
          console.log("Authorized")
          getAllEventsIn()
        }
      } else {
        console.log('Access token not found login required')
      }
    })()
  }, [])


  // JOYRIDE DEVELOPMENT
  const setTourTooltipViewed = () => {
    let toortipCount = localStorage.getItem('_FBN_GOALS_TOOLTIP_SHOW_');
    if (toortipCount) {
      toortipCount = parseInt(toortipCount);
      localStorage.setItem('_FBN_GOALS_TOOLTIP_SHOW_', toortipCount + 1);
    } else {
      localStorage.setItem('_FBN_GOALS_TOOLTIP_SHOW_', 1);
    }
  };

  const handleJoyrideCallback = async (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRun(false);
      setTourTooltipViewed();
    }
  };

  const handleChange = (
    newValue,
    actionMeta
  ) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    setSelectedGoal(newValue)
  };
  const handleInputChange = (inputValue, actionMeta) => {
    console.group('Input Changed');
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    setGoalName(inputValue);
    console.groupEnd();
  };

  const handleSync = () => {
    if (localStorage.getItem('google_access_token')) { }
  }

  const showAddBtn = () => {
    if (!selectedGoal) {
      return false
    }
    return !goals.filter(goal => !goal.deleted).find(goal => goal.id === selectedGoal.value)
  }

  const addDefaultGoal = async () => {
    if (selectedGoal && selectedGoal.value && !addGoalLoading) {
      try {
        // setLoading(true)
        setAddGoalLoading(true)
        const response = await axios.post(`${baseUrl}/plans-goals/default-goal`, {
          id: selectedGoal.value
        });
        // const goalsArray = [];
        // for (const key in response.data) {
        //   goalsArray.push({ ...response.data[key], id: key });
        // }
        let goal = response.data.data
        let [key] = Object.keys(goal)
        setGoals([goal[key], ...goals]);
        setGoalName('');
        setAddGoalLoading(false);
        // setLoading(false)
      } catch (error) {
        console.error(error);
        setError(error?.response?.data?.message || error.message);
        toast.error(error?.response?.data?.message || error.message);
        setAddGoalLoading(false);
        // setLoading(false)
      }
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <>
        <div
          css={css`
            background-color: #ecf8f8;
            font-family: "CircularStdBook";
          `}
        >
          <img
            className="no-print"
            src={BgImg}
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
            `}
          />
          <div className="no-print">
            <Header
              customWidth="88%"
              customMaxWidth="1440px"

              // customWidth="95%"
              // customMaxWidth="95em"
              fromGoal={!!true}
              setRun={setRun} isActive={isActive} showTooltipOnhover={showTooltipOnhover} />
          </div>
          <EWrapper>
            <EBlockWrapper>
              <EMainBlock>
                {!!isLoading
                  && (
                    <ELoadingWrapper>
                      <div className="text-center my-5">
                        <div
                          className="spinner-border assessmentSpinner papSpinner"
                          role="status"
                        ></div>
                        <span style={{ fontSize: '0.5rem' }} className="h1 ml-1">{t('summaryPage.loading')}</span>
                      </div>
                    </ELoadingWrapper>
                  )}


                {!isLoading
                  && (
                    <>
                      <div className="goal-content-wrapper">
                        <div className="goal-content-inner">
                          <h2>{t("goalPage.heading")}</h2>
                          <p>
                            {t("goalPage.pageInformation")}
                            {LOAD_INCENTIVES &&
                              t("goalPage.pageInformation2")
                            }
                            {t("goalPage.pageInformation3")}
                          </p>
                          {/* <NoGoals /> */}
                          <div className="full">
                            <div className="left">
                              <div className='dropdown-clear-part'>
                                <div className="dropdown symptom " data-tut="add-symptom-dropdown">
                                  <a ref={symptomsRef} className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    <span>{(rawAllSymptoms[selectedSymptoms] && rawAllSymptoms[selectedSymptoms].title[lang]) || t('goalPage.dropdownText1')}</span>
                                    <i className="fas fa-chevron-down" />
                                  </a>
                                  <div className="dropdown-menu custom-menu">
                                    {/* <div className="add-btn">
                                      <form onSubmit={e => { e.preventDefault(); addSymptoms(); }}>
                                        <input type="text" id="search-bar" placeholder={t('goalPage.addSymtoms')} value={symptomTitle} onChange={(e) => setSymptomTitle(e.target.value)} />
                                        <a onClick={e => { e.preventDefault(); addSymptoms(); }} href="#"><span>{t('goalPage.addbtnText')}</span></a>
                                      </form>
                                    </div> */}
                                    <p className='mb-0' onClick={(e) => { e.stopPropagation(); }}><strong style={{ fontSize: 14 }}>Recommended Symptoms</strong></p>
                                    <ul>
                                      {symptoms && symptoms.filter(symptom => Object.keys(symptom.goals || {}).length).map((symptom) => (
                                        <li className={symptom.id === selectedSymptoms ? 'active' : ''} onClick={() => filterGoals(symptom.id)} >
                                          {symptom.title && symptom.title[lang]}
                                          {/* <span>{Object.keys(symptom.goals || {}).length}</span> */}
                                        </li>
                                      ))}
                                    </ul>
                                    <hr />
                                    <p className='mb-0' onClick={(e) => { e.stopPropagation(); }}><strong style={{ fontSize: 14 }}>Other Symptoms</strong></p>
                                    <ul>
                                      {allSymptoms && allSymptoms.filter(symptom => Object.keys(symptom.goals || {}).length).filter(symptom => !rawSymptoms[symptom.id]).map(symptom => (
                                        <li className={symptom.id === selectedSymptoms ? 'active' : ''} onClick={() => filterGoals(symptom.id)}>
                                          {symptom.title && symptom.title[lang]}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                                {(rawAllSymptoms[selectedSymptoms] && rawAllSymptoms[selectedSymptoms].title[lang]) &&
                                  <a onClick={(e) => { e.preventDefault(); filterGoals(selectedSymptoms) }} className="clear-btn">
                                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" fill="#33ca8f"></path></svg>
                                  </a>
                                }
                              </div>
                            </div>
                            <div className="right">

                              <div className="add-btn custom-select-dropdown" data-tut="add-action-dropdown">
                                {/* <form ref={formRef} onSubmit={addGoal}> */}
                                <div>
                                  <CreatableSelect
                                    isLoading={addGoalLoading}
                                    openMenuOnFocus
                                    isClearable
                                    onChange={handleChange}
                                    onInputChange={handleInputChange}
                                    options={
                                      allGoals
                                        // .filter(goal => !goals.find(gl => gl.id === goal.id && !gl.deleted))
                                        .filter(goal => (selectedSymptoms && goal.symptoms[selectedSymptoms]) || !selectedSymptoms)
                                        .map(goal => ({ label: goal.title[lang], value: goal.id }))}
                                    ref={addGoalInput}
                                    disabled={addGoalLoading}
                                    id="search-bar"
                                    placeholder={t('goalPage.dropdownText2')}
                                    value={selectedGoal}
                                    styles={colourStyles(goals.find(goal => selectedGoal && selectedGoal.value === goal.id && !goal.deleted))}
                                    onCreateOption={addGoal}
                                  />

                                  {showAddBtn() &&
                                    <button className="btn-rect mt-3 false control-dropdown-btn" onClick={addDefaultGoal}>
                                      <img src={AddBtnIcon} />
                                      {t('goalPage.addbtnText')}
                                    </button>
                                  }
                                </div>

                                {/* </form> */}
                              </div>
                            </div>
                          </div>
                          <div className='icon-explainantion'>
                            <div className='left'>
                              <ul>
                                <li className="complete">{t("goalPage.iconExplaination.complete")}</li>
                                <li className="in-progress">{t("goalPage.iconExplaination.progress")}</li>
                                <li className="pending">{t("goalPage.iconExplaination.pending")}</li>
                              </ul>
                            </div>
                            {false && LOAD_GOOGLE_CALENDAR &&
                              <div className='right'>
                                <ul className='calendar-icons'>
                                  <li>
                                    <div className='calendar-status not-login'></div>
                                    {t("goalPage.iconExplaination.notLogin")}
                                  </li>
                                  <li>
                                    <div className='calendar-status sync'></div>
                                    {t("goalPage.iconExplaination.sync")}
                                  </li>
                                  <li className="pending">
                                    <div className='calendar-status not-sync'></div>
                                    {t("goalPage.iconExplaination.noSync")}
                                  </li>
                                </ul>
                              </div>
                            }
                          </div>
                          <div className='login-coin-btn-part'>
                            {LOAD_GOOGLE_CALENDAR &&
                              <div className='alert-part'>
                                {!googleLoggedin ?
                                  (<div class="alert alert-danger d-flex my-3" role="alert">
                                    <p>{t("goalPage.noUserLogin.text1")} <a href="#" data-tut="login-alert" onClick={e => { e.preventDefault(); googleLogIn() }} class="alert-link"> {t("goalPage.noUserLogin.text2")}</a> {t("goalPage.noUserLogin.text3")}</p>
                                  </div>)
                                  : (
                                    <div class="alert alert-success d-flex my-3" style={{ width: 'auto' }} data-tut="after-login-alert" role="alert">
                                      {/* <p>You are signed into your calendar. Press the sync button to synchronize your tasks</p> */}
                                      <p> You are signed into your calendar, you can<a href="#" data-tut="login-alert" onClick={e => { e.preventDefault(); localStorage.removeItem('google_refresh_token'); localStorage.removeItem('google_access_token'); localStorage.removeItem('google_expiry_date'); setGoogleLoggedin(false); }} class="alert-link">  logout</a> here. You can put tasks into your calendar by using a task’s ‘<strong>⋮</strong>’ menu.

                                        {/* You can put tasks into your calendar by using the ‘⋮’ <span><img src={Menu} /></span> task menu */}
                                      </p>
                                    </div>
                                  )}
                              </div>
                            }
                            {/* Auto allocate button */}
                            {/* {LOAD_INCENTIVES &&
                              <div className='allocation-button pull-right my-3 ml-auto' data-tut="allocate-coins-btn">
                                <button className='btn outline-btn-header'>Auto allocate coins</button>
                              </div>
                            } */}

                          </div>
                          {(!goals || goals.length === 0)
                            && <NoGoals inputRef={addGoalInput} />}
                          {/* accordion part */}
                          <div className="accordion-wrapper">
                            <div className="accordion" id="accordionExample">
                              {goals && goals.map((goal, index) => (
                                // ((selectedSymptoms && goal.symptoms[selectedSymptoms]) || !selectedSymptoms) ?
                                (
                                  // !goal.deleted && <Action ControlComponent={ControlComponent} setAddNewPlan={setAddNewPlan} deleteGoal={deleteGoal} planName={planName} setPlanName={setPlanName} addPlan={addPlan} addNewPlan={addNewPlan} symptoms={symptoms} onInputChange={onInputChange} i18n={i18n} goalIndex={index} goal={goal} updateTaskStatus={updateTaskStatus} updateGoalLoading={updateGoalLoading} updateTaskType={updateTaskType} updateTaskDays={updateTaskDays} getTaskDueDate={getTaskDueDate} onDateChange={onDateChange} updateWeekDays={updateWeekDays} deleteTask={deleteTask} deletePlan={deletePlan} addTask={addTask} settaskTitle={settaskTitle} taskTitle={taskTitle} getTaskTitle={getTaskTitle} editTitle={editTitle} goalTitles={goalTitles} setGoalTitles={setGoalTitles} toggleTitleInput={toggleTitleInput} updateGoalTitle={updateGoalTitle} setEditTitle={setEditTitle} setMenuIsOpen={setMenuIsOpen} menuIsOpen={menuIsOpen} CustomMenu={CustomMenu} addSymptomToGoal={addSymptomToGoal} setIsActive={setIsActive} isActive={isActive} showTooltipOnhover={showTooltipOnhover} />
                                  !goal.deleted &&
                                  <Action
                                    getAllEventsIn={getAllEventsIn}
                                    setUpdateGoalLoading={setUpdateGoalLoading}
                                    updateTaskEventId={updateTaskEventId}
                                    validateToken={validateToken}
                                    allEvents={allEvents}
                                    googleLoggedin={googleLoggedin}
                                    setGoogleLoggedin={setGoogleLoggedin}
                                    ControlComponent={ControlComponent}
                                    setAddNewPlan={setAddNewPlan}
                                    deleteGoal={deleteGoal}
                                    planName={planName}
                                    setPlanName={setPlanName}
                                    addPlan={addPlan}
                                    addNewPlan={addNewPlan}
                                    symptoms={symptoms}
                                    onInputChange={onInputChange}
                                    i18n={i18n}
                                    goalIndex={index}
                                    goal={goal}
                                    updateTaskStatus={updateTaskStatus}
                                    updateGoalLoading={updateGoalLoading}
                                    updateTaskType={updateTaskType}
                                    updateTaskDays={updateTaskDays}
                                    getTaskDueDate={getTaskDueDate}
                                    onDateChange={onDateChange}
                                    updateWeekDays={updateWeekDays}
                                    deleteTask={deleteTask}
                                    deletePlan={deletePlan}
                                    addTask={addTask}
                                    settaskTitle={settaskTitle}
                                    taskTitle={taskTitle}
                                    getTaskTitle={getTaskTitle}
                                    editTitle={editTitle}
                                    goalTitles={goalTitles}
                                    setGoalTitles={setGoalTitles}
                                    toggleTitleInput={toggleTitleInput}
                                    updateGoalTitle={updateGoalTitle}
                                    setEditTitle={setEditTitle}
                                    setMenuIsOpen={setMenuIsOpen}
                                    menuIsOpen={menuIsOpen}
                                    CustomMenu={CustomMenu}
                                    addSymptomToGoal={addSymptomToGoal}
                                    isActive={isActive}
                                    showTooltipOnhover={showTooltipOnhover}
                                    t={t}
                                    googleLogIn={googleLogIn}
                                    LOAD_INCENTIVES={LOAD_INCENTIVES}
                                    LOAD_GOOGLE_CALENDAR={LOAD_GOOGLE_CALENDAR}
                                    editDescription={editDescription}
                                    setEditDescription={setEditDescription}
                                    setGoalDescriptions={setGoalDescriptions}
                                    goalDescriptions={goalDescriptions}
                                  />
                                )
                                // : <></>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Joyride
                        callback={handleJoyrideCallback}
                        continuous={!!true}
                        run={run}
                        autostart
                        scrollToFirstStep={!!true}
                        showProgress={!!false}
                        showSkipButton={!!true}
                        steps={tourConfig}
                        floaterProps={{ options: { disableAnimation: true }, disableAnimation: true }}
                        styles={{
                          options: {
                            zIndex: 1000,
                            backgroundColor: '#e3ffeb',
                            primaryColor: '#33CA8F'
                          },
                        }}
                      />
                    </>
                  )}
              </EMainBlock>
            </EBlockWrapper>
          </EWrapper>
        </div>

        <div className="no-print">
          <Footer customClassName="custom-w" fromGoal={!!true} />
        </div>
        {LOAD_INCENTIVES &&
          <div>
            <ReactSlidingSidePanel />
          </div>
        }
      </>
    </ErrorBoundary>
  );
};
