import React, { useReducer, useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { StateContext } from "../../store/form/contexts"
import { baseUrl } from "../../lib/constants"
import appReducer from "../../store/form/reducers"
import { Name } from "./Name"
import { Password } from "./Password"
import { PasswordReenter } from "./PasswordReenter"
import { SelectSecurityQuestion } from "./SelectSecurityQuestion"
import { SecurityQuestionAnswer } from "./SecurityQuestionAnswer"
import { SelectDemographicQuestion } from "./SelectDemographicQuestion"
import { DropDownInput } from "./DropDownInput"
import DownloadApp from "../popups/mobile/DownloadApp"
import ScrollToID from "../../Modules/Assessment/ScrollToCard"
/** @jsx jsx */
import "../../css/forms.css"
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import _get from "lodash/get"
import { Switcher } from "./Switcher"
import { Weekdays } from "./Weekdays"
import { SharedModalBackground } from "../../utils/shared"
import OutsideClickHandler from "react-outside-click-handler"
import CloseImg from "../../../src/assets/images/close_icon.png"
import { AlphabeticalSorter } from "../../utils/AlphabeticalSorter"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../error/ErrorScreen"
import { clientAccessIds } from "../../lib/constants"
import { logEvent } from "../../index"

import * as EmailValidator from "email-validator"
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import { Auth } from "../../api/Auth"
import LogoSVG from "../../../src/assets/images/fbn-logo.svg"
import CopyIcon from "../../assets/images/copy.png"
import CloseImage from "../../../src/assets/images/close.svg"
import CopyToClipboard from "react-copy-to-clipboard"
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
// import { clearAPICache } from "../../utils/call"
// import { Tabs, Tab } from 'react-tabs-scrollable'
// import 'react-tabs-scrollable/dist/rts.css'
// import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
// import OutsideClickHandler from "react-outside-click-handler"
// import { SharedModalBackground } from "../../../utils/shared"

const EBlockWrapper = styled.div`
  font-family: "CircularStdBook";
  margin: 8em auto 0 auto;
  max-width: 1160px;
  padding: 0;
  position: relative;
  z-index: 1;
  @media (max-width:380px){
    padding: 0 10px !important;
  }
`

const EMainBlock = styled.div`
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 5px 20px rgba(48, 62, 56, 0.0660238);
  display: flex;
  flex-direction: column;
`

const ETopMainCaption = styled.h1`
  background-color: #f7f7f7;
  border-bottom: 2px solid #eee;
  color: #151852;
  display: flex;
  font-size: 1.4em;
  font-weight: 600;
  margin: 0;
  padding: 0.8em 1em;
`

const EMainBlockContent = styled.div`
  display: grid;
  grid-column-gap: 1.6em;
  grid-row-gap: 1.6em;
  grid-template-columns: 1fr 1fr;
  margin: 1.5em;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
  @media (max-width:380px){
    margin: 1.5em 1em;
  }
`

const ECardInfo = styled.div`
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #f0efef;
  display: flex;
  flex-direction: column;
`

const ECardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8em;
`

const ECardInfoCaptionBlock = styled.div`
  background: #f2fafa;
  border-bottom: 2px solid #f0efef;
  h2 {
    color: #383940;
    font-size: 1.1em;
    font-weight: 600;
    margin: 0;
  }
  padding: 0.9em 1em;
`

const EFieldCaption = styled.p`
  color: #383940;
  font-size: 1em;
  font-weight: 400;
  margin: 1.3em 0 0.4em 0;
  text-decoration: none;
  max-width:80%;
`

const ETextInput = styled.input`
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #d1d1d1;
  color: #383940;
  font-weight: 400;
  min-height: 2.7em;
  outline: 0;
  padding: 0 1em;
  transition: all 0.2s;
  :focus {
    background-color: #fff;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    outline: 0;
  }
`

const ETextArea = styled.textarea`
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #d1d1d1;
  color: #383940;
  font-weight: 400;
  min-height: 2.7em;
  outline: 0;
  padding: 0 1em;
  transition: all 0.2s;
  :focus {
    background-color: #fff;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    outline: 0;
  }
`

const ERadioButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.8em 0;

  p {
    margin: 0;
  }
`

const EDividerLine = styled.div`
  border-bottom: 1px solid #f5f5f5;
  width: 100%;
`

export const EModal = styled.div`
  min-width: 15em;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "1px 7px 20px 2px rgba(0, 0, 0, 0.4)"};
  background-color: #fff;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "5px"};
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // max-height: 82vh;
  width: 600px;
  position: relative;

  @media (max-width: 640px) {
    width: 90vw;
  }
`

export const EModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  h4 {
    font-size: 2em;
    font-weight: 600;
    color: #111;
  }

  p {
    font-size: 1em;
    color: #525151;
  }

  a {
    font-weight: 500;
    color: #2196f3 !important;
    text-decoration: underline;
    font-size: 0.9rem;
    margin: 1em 0;
    font-weight: 600;
    cursor: pointer;
    border: 0;
    outline: none;
    display: block;
  }
`

export const fetchCoins = async () => {
  const response = await fetch(baseUrl + `/coins`, {
    credentials: "include",
    mode: "cors",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();

  return data
}

export const Forms = (props) => {
  const { state, dispatch } = useContext(StateContext)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [displayMobileAppMessage, setDisplayMobileAppMessage] = useState(false)
  const [formData, setFormData] = useState({})

  const { t, i18n } = props;

  const [isDoctorEmailValid, setIsDoctorEmailValid] = useState(true)
  const [passwordInvalid, setPasswordInvalid] = useState(false)
  const [isContactValid, setIsContactValid] = useState(true)
  const [isFirstNameValid, setIsFirstNameValid] = useState(true)
  const [isLastNameValid, setIsLastNameValid] = useState(true)
  const [isAccessIdValid, setIsAccessIdValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const [submitMessage, setSubmitMessage] = useState(null)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [copied, setCopied] = useState(false)



  const [activeTab, setActiveTab] = React.useState(1)
  const onTabClick = (e, index) => {
    console.log(e)
    setActiveTab(index)
  }
  useEffect(() => {
    // setLoading(true);
    // fetch(baseUrl + `/user-profile`, {
    //   credentials: "include",
    //   mode: "cors",
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((res) => {
    //     logEvent("useEffect /user-profile successful")
    //     res.json()
    //   })
    //   .then((res) => {
    //     console.log(res)
    //     settingState(res);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     logEvent("useEffect /user-profile failed")
    //     console.log(err)
    //     setLoading(false);
    //   })
    setLoading(true);
    (async () => {
      try {
        const response = await fetch(baseUrl + `/user-profile`, {
          credentials: "include",
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        // let coinData = await fetchCoins()
        // console.log('coinData => ', coinData)
        logEvent("useEffect /user-profile successful")
        settingState(data);
        setLoading(false);
      } catch (err) {
        logEvent("useEffect /user-profile failed")
        console.log(err)
        setLoading(false);
      }
    })()
  }, [])

  const settingState = (res) => {
    setFormData(res);
    if (res.autoSendAssessment) {
      dispatch({ type: 'SET_DOCTOR_ASSESSMENT_EMAIL_NOTIFICATIONS', payload: res.autoSendAssessment });
    }
    if (res.autoSend) {
      dispatch({ type: "SET_DOCTOR_EMAIL_NOTIFICATIONS", payload: res.autoSend });
    }
    if (res.greetingMsg) {
      dispatch({ type: "SET_GREETING", payload: res.greetingMsg });
    }
    if (res.firstName !== undefined) {
      dispatch({ type: "SET_FIRST_NAME", payload: res.firstName })
    }
    if (res.lastName !== undefined) {
      dispatch({ type: "SET_LAST_NAME", payload: res.lastName })
    }
    if (res.doctorsEmail !== undefined) {
      dispatch({ type: "SET_DOCTOR_MAIL", payload: res.doctorsEmail })
    }
    if (res.email !== undefined) {
      dispatch({ type: 'SET_EMAIL', payload: res.email });
    }
    if (res.contact !== undefined) {
      dispatch({ type: "SET_CONTACT", payload: res.contact })
    }
    if (res.emailNotification !== undefined) {
      dispatch({
        type: "SET_EMAIL_NOTIFICATIONS",
        payload: res.emailNotification,
      })
    }
    if (res.phoneNotification !== undefined) {
      dispatch({
        type: "SET_PHONE_NOTIFICATIONS",
        payload: res.phoneNotification,
      })
    }
    if (res.textNotification !== undefined) {
      dispatch({
        type: "SET_TEXT_NOTIFICATIONS",
        payload: res.textNotification,
      })
    }
    if (res.weekday !== undefined) {
      dispatch({ type: "SET_WEEKDAY_NOTIFICATIONS", payload: res.weekday })
    }
    if (res.accessId !== undefined) {
      dispatch({ type: "SET_ACCESS_CODE", payload: res.accessId })
    }
    if (res.pdfPassword !== undefined) {
      dispatch({ type: "SET_PDF_PASSWORD", payload: res.pdfPassword })
    }
    if (res.gender !== undefined) {
      dispatch({ type: "SET_SINGLE_GENDER", payload: res.gender })
    }
    if (res.age !== undefined) {
      dispatch({ type: "SET_SINGLE_AGE", payload: res.age })
    }
    if (res.location !== undefined) {
      dispatch({ type: "SET_SINGLE_LOCATION", payload: res.location })
    }
    if (res.preferredLanguage !== undefined) {
      dispatch({ type: "SET_SINGLE_PREFERRED_LANGUAGE", payload: res.preferredLanguage })
    }
    if (res.weeksPerMonth !== undefined) {
      dispatch({ type: "SET_SINGLE_WEEKSPERMONTH", payload: res.weeksPerMonth })
    }
  }

  useEffect(() => {
    for (var i = 0; i < state.personalInfo.age.length; i++) {
      if (state.personalInfo.age[i].selected === true) {
        dispatch({
          type: "SET_SINGLE_AGE",
          payload: state.personalInfo.age[i].caption,
        })
      }
    }
  }, [state.personalInfo.age])

  useEffect(() => {
    for (var i = 0; i < state.personalInfo.gender.length; i++) {
      if (state.personalInfo.gender[i].selected === true) {
        dispatch({
          type: "SET_SINGLE_GENDER",
          payload: state.personalInfo.gender[i].caption,
        })
      }
    }
  }, [state.personalInfo.gender])
  useEffect(() => {
    for (var i = 0; i < state.personalInfo.preferredLanguage.length; i++) {
      if (state.personalInfo.preferredLanguage[i].selected === true) {
        dispatch({
          type: "SET_SINGLE_PREFERRED_LANGUAGE",
          payload: state.personalInfo.preferredLanguage[i].value,
        })
      }
    }
  }, [state.personalInfo.preferredLanguage])

  useEffect(() => {
    for (var i = 0; i < state.personalInfo.location.length; i++) {
      if (state.personalInfo.location[i].selected === true) {
        dispatch({
          type: "SET_SINGLE_LOCATION",
          payload: state.personalInfo.location[i].caption,
        })
      }
    }
  }, [state.personalInfo.location])

  useEffect(() => {
    for (var i = 0; i < state.notificationScheduler.weeksPerMonth.length; i++) {
      if (state.notificationScheduler.weeksPerMonth[i].selected === true) {
        dispatch({
          type: "SET_SINGLE_WEEKSPERMONTH",
          payload: state.notificationScheduler.weeksPerMonth[i].caption,
        })
      }
    }
  }, [state.notificationScheduler.weeksPerMonth])

  const submitHandler = () => {
    // var pattern = new RegExp(/^[0-9\b]+$/);
    if (
      (EmailValidator.validate(state.personalInfo.doctorsEmail || '') === true ||
        state.personalInfo.doctorsEmail == "") &&
      (!state.personalInfo.contact || isValidPhoneNumber(state.personalInfo.contact || ''))
      // && clientAccessIds.includes(state.personalInfo.organizationAccessCode)
      && state.personalInfo.pdfPassword.length > 7 && state.personalInfo.pdfPassword.length < 32
    ) {
      setPasswordInvalid(false)
      setIsDoctorEmailValid(true)
      setInvalidPassword(false)
      setIsContactValid(true)
      setIsAccessIdValid(true)
      setLoading(true);
      fetch(baseUrl + `/user-profile`, {
        credentials: "include",
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formatPostBody()),
      })
        .then((res) => res.status)
        .then((data) => {
          setLoading(false);
          logEvent("submitHandler /user-profile successful")
          if (data === 200) {
            setSubmitMessage(true)
            setIsAccessIdValid(true)
            setSuccessMessage("Form submitted successfully.")
            setTimeout(() => {
              setSubmitMessage(null)
            }, 3000)
            // history.push("/profile")
            // clearAPICache()
            window.location.href = '/profile'
          } else if (data === 400) {
            setIsAccessIdValid(false)
          } else {
            setErrorMessage(
              "Invalid inputs passed! Please enter all fields correctly."
            )
            setTimeout(() => {
              setErrorMessage("")
            }, 3000)
          }
        })
        .catch((err) => {
          logEvent("submitHandler /user-profile failed")
          console.log(err);
          setLoading(false);
          setErrorMessage(err);
        })
    } else {
      setSubmitMessage(false)
      if (
        EmailValidator.validate(state.personalInfo.doctorsEmail) === false &&
        state.personalInfo.doctorsEmail !== ""
      ) {
        setIsDoctorEmailValid(false)
      } else {
        setIsDoctorEmailValid(true)
      }
      if (!state.personalInfo.contact || isValidPhoneNumber(state.personalInfo.contact || '')) {
        setIsContactValid(true)
      } else {
        setIsContactValid(false)
      }
      console.log('password length : ', state.personalInfo.pdfPassword.length)
      if (state.personalInfo.pdfPassword.length <= 7 || state.personalInfo.pdfPassword.length >= 32) {
        setPasswordInvalid(true)
      } else {
        setPasswordInvalid(false)
      }

      // if (
      //   clientAccessIds.includes(state.personalInfo.organizationAccessCode) ===
      //   false
      // ) {
      //   setIsAccessIdValid(false)
      // } else {
      //   setIsAccessIdValid(true)
      // }

      if (!(state.personalInfo.pdfPassword.length > 5 && state.personalInfo.pdfPassword.length < 36)) {
        setInvalidPassword(true)
      }
    }
  }
  const formatPostBody = () => {
    return {
      personalInfo: {
        firstName: state.personalInfo.firstName,
        lastName: state.personalInfo.lastName,
        gender: state.personalInfo.singleGender,
        age: state.personalInfo.singleAge,
        location: state.personalInfo.singleLocation,
        preferredLanguage: state.personalInfo.singlePreferredLanguage,
        doctorsEmail: state.personalInfo.doctorsEmail,
        contact: state.personalInfo.contact || null,
        accessId: state.personalInfo.organizationAccessCode,
        pdfPassword: state.personalInfo.pdfPassword,
        autoSend: state.personalInfo.autoSend,
        autoSendAssessment: state.personalInfo.autoSendAssessment,
        greetingMsg: state.personalInfo.greetingMsg,
        notificationSettings: {
          isEmail: state.notificationScheduler.email,
          isPhone: state.notificationScheduler.phone,
          isText: state.notificationScheduler.textNotifications
        }
      },
      notificationScheduler: {
        emailNotification: state.notificationScheduler.email,
        phoneNotification: state.notificationScheduler.phone,
        textNotification: state.notificationScheduler.textNotifications,
        weekday: state.notificationScheduler.weekday,
        weeksPerMonth: state.notificationScheduler.singleWeeksPerMonth,
      },
      demographicQuestions: {},
      securityQuestions: {},
    }
  }

  const handleFirstNameChange = (e) => {
    dispatch({ type: "SET_FIRST_NAME", payload: e.target.value })
  }

  const handleLastNameChange = (e) => {
    dispatch({ type: "SET_LAST_NAME", payload: e.target.value })
  }

  const handleGreetingMsgChange = (e) => {
    dispatch({ type: "SET_GREETING", payload: e.target.value })
  }

  const handleGenderChange = (newlist) => {
    dispatch({ type: "SET_GENDER", payload: newlist })
  }

  const handleAgeChange = (newlist) => {
    dispatch({ type: "SET_AGE", payload: newlist })
  }

  const handleLanguageChange = (newlist) => {
    dispatch({ type: "SET_PREFERRED_LANGUAGE", payload: newlist })
  }

  const handleLocationChange = (newlist) => {
    dispatch({ type: "SET_LOCATION", payload: newlist })
  }

  const handleDoctorEmailChange = (e) => {
    if (!e.target.value) {
      dispatch({ type: "SET_DOCTOR_ASSESSMENT_EMAIL_NOTIFICATIONS", payload: false });
      dispatch({ type: "SET_DOCTOR_EMAIL_NOTIFICATIONS", payload: false });
    }
    dispatch({ type: "SET_DOCTOR_MAIL", payload: e.target.value });
  }

  const handleContactChange = (e) => {
    console.log(e)
    if (!e) {
      dispatch({
        type: "SET_TEXT_NOTIFICATIONS",
        payload: false,
      })
    }
    dispatch({ type: "SET_CONTACT", payload: e })
  }

  const handleAccessCodeChange = (e) => {
    dispatch({ type: "SET_ACCESS_CODE", payload: e.target.value })
  }
  const handlePasswordChange = (e) => {
    if (e.target.value.length < 32) {
      dispatch({ type: "SET_PDF_PASSWORD", payload: e.target.value })
    }
  }
  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
      Password copy
    </Popover>
  );

  const handleEmailDoctorChange = (bool, type = 'SET_DOCTOR_EMAIL_NOTIFICATIONS') => {
    if (bool) {
      if (state.personalInfo && state.personalInfo.doctorsEmail && EmailValidator.validate(state.personalInfo.doctorsEmail || '')) {
        setIsDoctorEmailValid(true)
        dispatch({ type, payload: bool })
      } else {
        setIsDoctorEmailValid(false)

        setErrorMessage(
          "Please enter valid email"
        )
        setTimeout(() => {
          setErrorMessage("")
        }, 3000)
        ScrollToID('email')
      }
    } else {
      dispatch({ type, payload: bool })
    }
  }

  const handleEmailChange = (bool) => {
    return dispatch({ type: "SET_EMAIL_NOTIFICATIONS", payload: bool })
    if (bool) {
      if (state.personalInfo && state.personalInfo.doctorsEmail && EmailValidator.validate(state.personalInfo.doctorsEmail || '')) {
        setIsDoctorEmailValid(true)
        dispatch({ type: "SET_EMAIL_NOTIFICATIONS", payload: bool })
      } else {
        setIsDoctorEmailValid(false)

        setErrorMessage(
          "Please enter valid email"
        )
        setTimeout(() => {
          setErrorMessage("")
        }, 3000)
        ScrollToID('email')
      }
    } else {
      dispatch({ type: "SET_EMAIL_NOTIFICATIONS", payload: bool })
    }
  }

  const handlePhoneNotificationChange = (bool) => {
    console.log('debug text', bool)
    if (bool) {
      if (state.personalInfo && state.personalInfo.contact && isValidPhoneNumber(state.personalInfo.contact || '')) {
        setIsContactValid(true)
        dispatch({ type: "SET_TEXT_NOTIFICATIONS", payload: bool })
      } else {
        setIsContactValid(false)

        setErrorMessage(
          "Please enter valid phone number"
        )
        setTimeout(() => {
          setErrorMessage("")
        }, 3000)
        ScrollToID('phone')
      }
    } else {
      // console.log('debug text in false b', state.notificationScheduler.textNotifications)
      dispatch({ type: "SET_TEXT_NOTIFICATIONS", payload: false })
    }
    console.log('debug text in false a', state.notificationScheduler.textNotifications)
  }

  const handleTextNotificationChange = (bool) => {
    dispatch({ type: "SET_TEXT_NOTIFICATIONS", payload: bool })
  }

  const handleWeekdayChange = (str) => {
    dispatch({ type: "SET_WEEKDAY_NOTIFICATIONS", payload: str })
  }

  const handleWeekPerMonthChange = (newlist) => {
    dispatch({ type: "SET_WEEKS_PER_MONTH", payload: newlist })
  }

  const sortLocations = () => {
    return AlphabeticalSorter(state.personalInfo.location)
  }

  const logOut = () => {
    try {
      Auth.logout().then(() => {
        dispatch({
          type: "HANDLE_AUTH_LOGOUT",
        });
        window.location.replace('/');
      });
    } catch (err) {
      console.log({ err });
    }
  }

  const deleteAccount = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('This action cannot be undone. Are you sure you want to delete this account?')) {
      setLoading(true);
      // await axios.delete(baseurl + "/user-profile")
      let response = await fetch(baseUrl + "/user-profile", {
        credentials: "include",
        mode: "cors",
        method: "DELETE"
      })
      if (response.ok) {
        response = await response.json()
        setLoading(false);
        alert('Account data deleted successfully');
        logOut()
      } else {
        alert('An error occurred while deleting the account');
      }
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <>
        <EBlockWrapper style={{ maxWidth: "1160px", margin: "10px auto 0 auto", padding: "0 20px 0 20px" }}>
          <EMainBlock id="#profile">
            <ETopMainCaption>{t("profile.head")}</ETopMainCaption>

            {loading ?
              <div className="d-flex justify-content-center m-4">
                <div className="spinner-border text-primary" role="status" /></div>
              : <>
                <EMainBlockContent>
                  <ECardInfo>
                    <ECardInfoCaptionBlock>
                      <h2>{t("profile.header")}</h2>
                    </ECardInfoCaptionBlock>
                    <ECardContent>
                      <EFieldCaption>{t("profile.subHeader")}</EFieldCaption>
                    </ECardContent>

                    <ECardContent>
                      {/* <EFieldCaption>{t("profile.greetings")}</EFieldCaption>
                      <ETextArea rows="6" onChange={handleGreetingMsgChange}>{state.personalInfo.greetingMsg || 'Hello _______. You are receiving this communication with important information from ____. For security’s sake I have moved the contents of this email into the “Instructions” where you will find instructions. All of the documents are in password protected PDF’s. Please contact me for the password. Thank you in advance for complying with this request.'}</ETextArea> */}
                      <EFieldCaption>{t("profile.gender")}</EFieldCaption>
                      <DropDownInput
                        onChange={handleGenderChange}
                        list={state.personalInfo.gender}
                        defaultPlaceholder={state.personalInfo.singleGender}
                      />

                      <EFieldCaption>{t("profile.age")}</EFieldCaption>
                      <DropDownInput
                        onChange={handleAgeChange}
                        list={state.personalInfo.age}
                        defaultPlaceholder={state.personalInfo.singleAge}
                      />

                      <EFieldCaption>{t("profile.prefLanguage")}</EFieldCaption>
                      <DropDownInput
                        onChange={handleLanguageChange}
                        list={state.personalInfo.preferredLanguage}
                        defaultPlaceholder={state.personalInfo.singlePreferredLanguage === "en" ? "English" : state.personalInfo.singlePreferredLanguage === "fr" ? "French" : ""}
                      />

                      <EFieldCaption>{t("profile.location")}</EFieldCaption>
                      <DropDownInput
                        onChange={handleLocationChange}
                        list={sortLocations()}
                        defaultPlaceholder={state.personalInfo.singleLocation}
                      />

                      <EFieldCaption>{t("profile.phone")}</EFieldCaption>
                      <Input
                        id="phone"
                        style={{
                          background: "#ffffff",
                          borderRadius: "3px",
                          border: "1px solid #d1d1d1",
                          color: '#383940',
                          fontWeight: "400",
                          minHeight: "2.7em",
                          outline: 0,
                          padding: "0 1em"
                        }}
                        country="US"
                        value={state.personalInfo.contact}
                        maxLength="14"
                        onChange={handleContactChange}
                        style={{
                          background: "#ffffff",
                          borderRadius: "3px",
                          color: '#383940',
                          fontWeight: "400",
                          minHeight: "2.7em",
                          outline: 0,
                          padding: "0 1em",
                          border:
                            (isContactValid === false) ? "1px solid red" : "1px solid #d1d1d1",
                        }}
                      />
                      {isContactValid === false ? (
                        <p>{t("profile.validContact")}</p>
                      ) : (
                        <p></p>
                      )}

                      {/* <EFieldCaption>{t("profile.docEmail")}</EFieldCaption>
                      <ETextInput
                        id="email"
                        value={state.personalInfo.doctorsEmail}
                        onChange={handleDoctorEmailChange}
                        type="email"
                        maxLength={350}
                        style={{
                          border:
                            isDoctorEmailValid === false ? "1px solid red" : null,
                        }}
                      />
                      {isDoctorEmailValid === false ? (
                        <p>{t("profile.validEmail")}</p>
                      ) : (
                        <p></p>
                      )} */}

                      <EFieldCaption>{t("aboutUsPage.formSection.emailLabel")}</EFieldCaption>
                      <ETextInput
                        id="email"
                        value={state.personalInfo.email}
                        onChange={() => { }}
                        type="email"
                        maxLength={350}
                        disabled
                        // className="disabled"
                        style={{
                          background: 'lightgray',
                          opacity: 0.4,
                          pointerEvents: 'none'
                        }}
                      />
                    </ECardContent>
                  </ECardInfo>

                  <div>
                    <ECardInfo>
                      <ECardInfoCaptionBlock>
                        <h2>{t("profile.notifScheduler")}</h2>
                      </ECardInfoCaptionBlock>

                      <ECardContent>
                        {/* <ERadioButtonWrapper
                          css={css`
                        margin-top: 0;
                      `}
                        >
                          <EFieldCaption
                            css={css`
                          margin-top: 0;
                        `}
                          >
                            {t("profile.sendToDoc")}
                          </EFieldCaption>
                          <Switcher
                            onChange={handleEmailDoctorChange}
                            enabled={state.personalInfo.autoSend}
                          />
                        </ERadioButtonWrapper> */}

                        {/* <EDividerLine />

                        <ERadioButtonWrapper >
                          <EFieldCaption
                            css={css`
                          margin-top: 0;
                        `}
                          >
                            {t("profile.sendAssessmentToDoc")}
                          </EFieldCaption>
                          <Switcher
                            onChange={(e) => handleEmailDoctorChange(e, 'SET_DOCTOR_ASSESSMENT_EMAIL_NOTIFICATIONS')}
                            enabled={state.personalInfo.autoSendAssessment}
                          />
                        </ERadioButtonWrapper> */}

                        <EDividerLine />

                        <ERadioButtonWrapper >
                          <EFieldCaption
                            css={css`
                          margin-top: 0;
                        `}
                          >
                            {t("profile.email")}
                          </EFieldCaption>
                          <Switcher
                            onChange={handleEmailChange}
                            enabled={state.notificationScheduler.email}
                          />
                        </ERadioButtonWrapper>

                        <EDividerLine />



                        {/* <ERadioButtonWrapper>
                          <EFieldCaption
                            css={css`
                          margin-top: 0;
                        `}
                          >
                            {t("profile.phoneNotif")}
                          </EFieldCaption>
                          <Switcher
                            onChange={handlePhoneNotificationChange}
                            enabled={state.notificationScheduler.phone}
                          />
                        </ERadioButtonWrapper> */}

                        <EDividerLine />

                        <ERadioButtonWrapper>
                          <EFieldCaption
                            css={css`
                          margin-top: 0;
                        `}
                          >
                            {t("profile.textNotif")}
                          </EFieldCaption>
                          <Switcher
                            onChange={handlePhoneNotificationChange} //{/* handleTextNotificationChange */}
                            enabled={state.notificationScheduler.textNotifications}
                          />
                        </ERadioButtonWrapper>

                        <EDividerLine />

                        <div
                          css={css`
                        margin: 1.2em 0;
                      `}
                        >
                          <Weekdays
                            onChange={handleWeekdayChange}
                            value={state.notificationScheduler.weekday}
                          />
                        </div>

                        <ERadioButtonWrapper
                          css={css`
                        display: flex;
                        justify-content: space-between;
                      `}
                        >
                          <EFieldCaption>{t("profile.wpm")}</EFieldCaption>
                          <div
                            css={css`
                          width: 8em;
                        `}
                          >
                            <DropDownInput
                              onChange={handleWeekPerMonthChange}
                              list={state.notificationScheduler.weeksPerMonth}
                              defaultPlaceholder={
                                state.notificationScheduler.singleWeeksPerMonth
                              }
                            />
                          </div>
                        </ERadioButtonWrapper>

                        {/* <EDividerLine
                          css={css`
                        margin: 0.8em 0;
                      `}
                        />

                         <EFieldCaption
                          css={css`
                        color: #4368d4;
                        margin: 0.2em 0;
                        border: 0;
                        align-self: flex-start;
                        border: 0;
                        background: transparent;
                      `}
                          as="button"
                          onClick={() => {
                            setDisplayMobileAppMessage(true)
                          }}
                        >
                          {t("profile.app")}
                        </EFieldCaption> */}
                      </ECardContent>
                    </ECardInfo>

                    <ECardInfo style={{ marginTop: "1.5rem" }}>
                    <ECardInfoCaptionBlock>
                      <h2>{t("profile.pdfPasswordSTitle")}</h2>
                    </ECardInfoCaptionBlock>

                    <ECardContent>

                      <EFieldCaption
                        css={css`
                      color: #4368d4;
                      margin: 0.1em 0;
                    `}
                        as="a"
                        href="https://mensanteid.b2clogin.com/bd2bbd66-bd80-417e-9a3d-bbf975f4d919/oauth2/v2.0/authorize?p=B2C_1_reset_password_flow&client_id=ffbaada4-c315-47b7-b500-e1b96eccaaaa&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fequitable.feelingbetternow.com&scope=openid&response_type=id_token&prompt=login"
                      >
                        {t("profile.updatePw")}
                      </EFieldCaption>

                      {/* <div
                        css={css`
                      height: 2.2em;
                    `}
                      /> */}

                      <EFieldCaption
                        css={css`
                      margin: 0.1em 0;
                    `}
                      >
                        {t("profile.pdfPassword")}
                      </EFieldCaption>


                      {/*  coppy part */}

                      <div
                        css={css`
                            position: relative;
                        `}>

                        <ETextInput
                          disabled={true}
                          min={8}
                          max={32}
                          value={state.personalInfo.pdfPassword}
                          onChange={handlePasswordChange}
                          type="text"
                          css={css`
                            margin-top: 0.5em;
                            background: lightgray;
                            opacity: 0.4;
                            pointer-events: none;
                            width: 100%;
                          `}
                          style={{
                            border:
                              passwordInvalid ? "1px solid red" : null,
                          }}
                        />
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popoverBottom}>
                          <CopyToClipboard
                            onCopy={() => setCopied(true)} text={state.personalInfo.pdfPassword}
                          >
                            <img src={CopyIcon} alt="coppyIcon"
                              css={css`
                                  position: absolute;
                                  width: 20px;
                                  right: 12px;
                                  top: 20px;
                                  cursor: pointer;
                                `} />


                          </CopyToClipboard>
                        </OverlayTrigger>

                      </div>
                      {passwordInvalid && <p>Please enter a valid password.</p>}

                      
                    </ECardContent>
                  </ECardInfo>

                    {/* <ECardInfo className="mt-4 jumstart-coin-allocation-card">
                    <ECardInfoCaptionBlock>
                      <h2>Jumpstart Earning</h2>
                    </ECardInfoCaptionBlock>

                    <ECardContent>
                      <h6 css={css`font-size:18px; font-weight:500 `}>Coin Balance:<span css={css`color:#33CA8F;margin-left:4px`}>5000</span></h6>

                      <EFieldCaption
                        css={css`
                          margin: 0.1em 0;
                        `}
                      >
                        Coin Point Balance
                      </EFieldCaption>
                      
                      <table>
                        <thead>
                          <tr>
                            <th>Plan Name</th>
                            <th>Point Value</th>
                            <th>Completion Date</th>
                            <th>Earned</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Crisis Plan</td>
                            <td>2000</td>
                            <td>21/11/2022</td>
                            <td className="earn-point green">+ 500</td>
                          </tr>
                          <tr>
                            <td>Create Crisis Plan</td>
                            <td>2000</td>
                            <td>21/11/2022</td>
                            <td className="earn-point green">+ 500</td>
                          </tr>
                          <tr>
                            <td>Crisis Plan</td>
                            <td>2000</td>
                            <td>21/11/2022</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Create Crisis Plan</td>
                            <td>2000</td>
                            <td>21/11/2022</td>
                            <td className="earn-point red">- 200</td>
                          </tr>
                        </tbody>
                      </table>

                    </ECardContent>
                    </ECardInfo> */}
                  </div>

                  <ECardInfo>
                    <ECardInfoCaptionBlock>
                      <h2>{t("profile.account")}</h2>
                    </ECardInfoCaptionBlock>

                    <ECardContent>
                      {/* <EFieldCaption
                        css={css`
                      margin: 0.1em 0;
                    `}
                      >
                        {t("profile.update")}
                      </EFieldCaption> */}

                      <EFieldCaption
                        css={css`
                      margin: 0.1em 0;
                    `}
                      >
                        {t("profile.contact")}
                      </EFieldCaption>

                      <ETextInput
                        value={
                          state.personalInfo.organizationAccessCode == false
                            ? ""
                            : state.personalInfo.organizationAccessCode
                        }
                        onChange={handleAccessCodeChange}
                        type="email"
                        css={css`
                      margin-top: 0.5em;
                    `}
                        style={{
                          border:
                            isAccessIdValid === false ? "1px solid red" : null,
                        }}
                      />
                      {isAccessIdValid === false ? (
                        <p>{t("profile.validCode")}</p>
                      ) : (
                        <p></p>
                      )}
                      <button
                        css={css`
                        text-transform: uppercase;
                        color: #fff;
                        background: var(--red);
                        border-radius: 0.3em;
                        align-self: flex-start;
                        padding: 0.7em 3.5em;
                        outline: none;
                        border: 0;
                        margin-top: 1.5em;
                        :focus {
                          outline: none;
                        }
                      `}
                        onClick={deleteAccount}
                      >
                        {t("profile.delete")}
                      </button>
                    </ECardContent>
                  </ECardInfo>

                </EMainBlockContent>
              </>
            }

            <EDividerLine />

            {!loading &&
              <button
                css={css`
                text-transform: uppercase;
                color: #fff;
                background: #33ca8f;
                border-radius: 0.3em;
                align-self: flex-start;
                padding: 0.7em 3.5em;
                outline: none;
                border: 0;
                margin: 1.5em;
                :focus {
                  outline: none;
                }
              `}
                onClick={submitHandler}
              >
                {t("profile.updateBtn")}
              </button>}
            {submitMessage !== null ? (
              submitMessage === true ? (
                <div
                  className="alert alert-success"
                  role="alert"
                  style={{
                    padding: "0.7em 3.5em ",
                    marginLeft: "1.5em",
                    marginRight: "1.5em",
                    marginBottom: "1.5em",
                  }}
                >
                  {successMessage}
                  {/* Form submitted successfully. */}
                </div>
              ) : (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    //padding: "0.7em 3.5em ",
                    marginLeft: "1.5em",
                    marginRight: "1.5em",
                    marginBottom: "1.5em",
                  }}
                >
                  Oops! something went wrong.  Please check your answers and try again
                </div>
              )
            ) : null}
          </EMainBlock>
        </EBlockWrapper>

        {displayMobileAppMessage && (
          <>
            <SharedModalBackground />

            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                z-index: 1100;
              `}
            >
              <OutsideClickHandler

              >
                {/* <EModal>
                  <>
                    <EModalContent>
                      <div
                        css={css`
                          // padding: 20px 20px 0 20px;
                          padding: 40px 20px 40px 20px;
                        `}
                      >
                        <img
                          src={CloseImg}
                          onClick={() => {
                            setDisplayMobileAppMessage(false)
                          }}
                          css={css`
                            height: 2.5em;
                            position: absolute;
                            right: 1em;
                            top: 1em;
                          `}
                        />

                        <h4
                          css={css`
                            margin: 0;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                          `}
                        >
                          How to get FeelingBetterNow on Mobile
                        </h4>

                        <a>Playstore Link</a>
                        <a>AppStore Link</a>

                        <p>
                          With FeelingBetterNow on mobile; you can do
                          assessments, review outcomes and share personal action
                          plans anytime and anywhere!
                        </p>
                        <p>www.feelingbetternow.com/mobile etc</p>
                        <p>
                          Share this link a friend or with yourself to download
                          the app. You can schedule assessment notifications
                          here on FeelingBetterNow.com or on your native apps/
                        </p>
                      </div>
                    </EModalContent>
                  </>
                </EModal> */}
                {/* <div className="downloadapp-container">
                  <DownloadApp canDelete={false} onClose={() => {
                    document.body.style.overflow = "scroll";
                    setDisplayMobileAppMessage(false)
                  }} />
                </div> */}
              </OutsideClickHandler>
            </div>
          </>
        )}
      </>
    </ErrorBoundary>
  )
}
