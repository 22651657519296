import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import '../../css/Home.scss';
import '../../css/newsCarousal.scss';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { HashLink } from 'react-router-hash-link';
import moment from 'moment';
import { baseUrl, loginUrl, getLoginURL } from '../../lib/constants';
import { StateContext } from '../../store/state/contexts';
import { Auth } from '../../api/Auth';
import { AssessmentsHistoryWrapper } from '../assessmentHistory/AssessmentsHistoryWrapper';
import { ClientAccessId } from '../popups/accessCode/ClientAccessId';
import AssessmentGap from '../popups/accessCode/FollowUpGap';
import LoginPopup from '../popups/accessCode/LoginPopup';
import { logEvent } from '../../index';
import { Pap } from '../../api/pap';
import { makepdf } from '../../Modules/PAP/PAPMake';
import generatePassword from '../../utils/generatePassword';

const qs = require('qs');
const store = require('store');

// import "../css/animate.scss";

function Header(props) {
    const { state, dispatch } = useContext(StateContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [lang, setLang] = useState(store.get('lang') ? store.get('lang') : 'en');

    const [showClientAccessPopUp, setShowClientAccessPopUp] = useState(false);
    const [hasClient, setHasClient] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [loading, setLoading] = useState(false);

    const [AssessmentDisorders, setAssessmentDisorders] = useState([]);
    const [assessmentDisordersLoading, setAssessmentDisordersLoading] = useState(true);
    const [latestAssessmentId, setlatestAssessmentId] = useState(null);

    const [showAssessmentGapPopup, setshowAssessmentGapPopup] = useState(false);
    const closeAssessmentGapPopup = () => {
        setshowAssessmentGapPopup(false);
    };

    const [showLoginPopup, setshowLoginPopup] = useState(false);
    const closeLoginPopup = () => {
        setshowLoginPopup(false);
    };

    const getDetails = async (link) => {
        try {
            dispatch({ type: 'SET_DETAILS_DATA_LOADING', payload: true });
            const response = await fetch(link, {
                credentials: 'include',
                mode: 'cors',
            });
            if (response.status >= 200 && response.status <= 299) {
                const details = await response.json();
                logEvent('getDetails /assessment/details successful');
                dispatch({ type: 'SET_DETAILS_DATA', payload: details });
                dispatch({ type: 'SET_DETAILS_DATA_LOADING', payload: false });
                return response;
            }
            dispatch({ type: 'SET_DETAILS_DATA_LOADING', payload: false });
            // setOpen(true);
            // setIsError(true);
            // setTimeout(() => {
            //   history.goBack();
            // }, 5000);
        } catch (err) {
            dispatch({ type: 'SET_DETAILS_DATA_LOADING', payload: false });
            //   setOpen(true);
            //   setIsError(true);
            //   setTimeout(() => {
            //     history.goBack();
            //   }, 5000);
            console.log('file: ====================== Summary === src/Modules/summary/SummaryDetails.jsx ================');
            console.log(err);
            return {};
        }
    };

    const getPAPDetails = async (id) => {
        Pap.get(id).then((data) => { setAssessmentDisorders(data.data); setAssessmentDisordersLoading(false); });
        return AssessmentDisorders;
    };

    const {
        auth: { isLoggedIn, email },
        historyData,
    } = state;

    const {
        t, i18n, customWidth, customMaxWidth, education_id, fromPAP, setRun, fromSummary, fromGoal, showTooltipOnhover, isActive,
    } = props;
    let latestAssessment;
    if (historyData) {
        historyData.map((hd) => {
            if (latestAssessment) {
                if (moment(hd.completed_at).isAfter(moment(latestAssessment.completed_at))) {
                    latestAssessment = hd;
                }
            } else {
                latestAssessment = hd;
            }
        });
    }

    useEffect(() => {
        if (latestAssessment
            // && latestAssessmentId != latestAssessment._id
        ) {
            // console.log('auth', `${latestAssessmentId} !== ${latestAssessment._id} = ${latestAssessmentId != latestAssessment._id}`)
            setlatestAssessmentId(latestAssessment._id);
            setAssessmentDisordersLoading(true);
            getPAPDetails(latestAssessment._id);
            getDetails(`${baseUrl}/assessment/details/${latestAssessment._id}`);
        }
    }, [latestAssessment]);

    const { detailsData } = state;
    const { auth } = state;
    const { detailsDataLoading } = state;
    // console.log('auth', `${!!detailsData} && ${!assessmentDisordersLoading} && ${!!historyData}`)
    const handleDownloadMapClick = (e) => {
        e.preventDefault();
        if (detailsData && !assessmentDisordersLoading && historyData) {
            let passwordChanged = false;
            if (!detailsData.user || !detailsData.user.pdfPassword) {
                const autoGeneratedPassword = generatePassword();
                detailsData.user.pdfPassword = autoGeneratedPassword;
                passwordChanged = true;
            }
            makepdf(
                detailsData,
                AssessmentDisorders,
                historyData,
                'download',
                state,
                passwordChanged,
            );
        }
    };
    // console.log('auth', latestAssessment)
    const checkClientID = async () => {
        try {
            const res = await fetch(`${baseUrl}/user/accesscode`, {
                credentials: 'include',
                mode: 'cors',
                method: 'GET',
            });
            const assementCode = await res.json();
            if (!assementCode || (typeof assementCode === 'object' && Object.keys(assementCode).length === 0)) {
                logEvent('checkClientID /user/accesscode successful');
                setHasClient(false);
            } else {
                setHasClient(true);
                logEvent('checkClientID /user/accesscode failed');
            }
        } catch (err) {
            setHasClient(false);
            console.log(err);
            logEvent('checkClientID /user/accesscode failed');
        }
    };

    const checkAssessmentInProgress = async () => {
        setLoading(true);
        // alert('set')
        fetch(`${baseUrl}/assessment/inprogress`, {
            credentials: 'include',
            mode: 'cors',
            method: 'GET',
        }).then((res) => res.json())
            .then((res) => {
                if (res == '') {
                    // setLoading(false)
                    setInProgress(false);
                    logEvent('checkAssessmentInProgress /assessment/inprogress failed');
                } else {
                    // setLoading(false)
                    setInProgress(true);
                    logEvent('checkAssessmentInProgress /assessment/inprogress successful');
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        checkClientID();
        checkAssessmentInProgress();
    }, []);

    const getLoginURL = (nonce) => `https://mensanteid.b2clogin.com/mensanteid.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_fbn_signup_signin&client_id=ffbaada4-c315-47b7-b500-e1b96eccaaaa&nonce=${`${encodeURIComponent(nonce)
        }&redirect_uri=${encodeURIComponent(`${baseUrl}/callback`)}`
        }&scope=openid&response_type=id_token&prompt=login&response_mode=form_post`;

    const getNonce = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const nonce = urlParams.get('code');
        if (nonce != null) return nonce;
        return 'defaultNonce';
    };

    const logOut = (e) => {
        e.preventDefault();
        try {
            Auth.logout().then((res) => {
                dispatch({
                    type: 'HANDLE_AUTH_LOGOUT',
                });
                window.location.replace('/');
            });
        } catch (err) {
            console.log({ err });
        }
    };
    const handleMenuClick = (e) => {
        $('.only-show-in-mobile').fadeToggle();
        $('.only-show-in-mobile').toggleClass('sidebar-show');
        $('.header-backdrop').toggleClass('body-overlay');
        $('#open-mobile-menu').toggleClass('active');
    };

    const handleClosePopup = () => {
        setShowClientAccessPopUp(false);
    };

    const headerStyle = {
        top: '3%',
        width: customWidth || '100%',
    };

    let allowAssessment = false;

    if ((detailsData && detailsData.user && detailsData.user._id) || auth.isLoggedIn) {
        if (detailsData && detailsData.user && detailsData.user.lastAssessment) {
            if (moment().isAfter(moment(detailsData.user.lastAssessment).add(14, 'days'))) {
                allowAssessment = true;
            }
        } else {
            allowAssessment = true;
        }
    }
    if (customMaxWidth) headerStyle.maxWidth = customMaxWidth;
    return (
        <>
            <LoginPopup
                i18n={i18n}
                t={t}
                show={showLoginPopup}
                handleClose={closeLoginPopup}
                onHide={closeLoginPopup}
                lastAssessment={detailsData?.user?.lastAssessment}
            />
            <AssessmentGap
                i18n={i18n}
                t={t}
                show={showAssessmentGapPopup}
                handleClose={closeAssessmentGapPopup}
                onHide={closeAssessmentGapPopup}
                lastAssessment={detailsData?.user?.lastAssessment}
            />
            <ClientAccessId
                i18n={i18n}
                t={t}
                show={showClientAccessPopUp}
                handleClose={handleClosePopup}
                onHide={handleClosePopup}
            />
            <div
                className={`nav homeNav customHeader wow fadeInUp  ${fromPAP == true ? 'PAP-headerSmalldevices' : ''} ${fromGoal == true ? 'goal-page-container' : ''} `}
                style={headerStyle}
            >
                <div className="header-backdrop" onClick={handleMenuClick} />
                <ul className="logo-part equitable-part">
                    <li className="nav-item d-flex align-items-center header-logo">
                        <Link to="/">
                            <div className="logo-size">
                                <img
                                    src={require('../../assets/images/FeelingBetterNowLogo.svg').default}
                                    alt="Feeling Better Now"
                                    style={{ height: 40 }}
                                    className="align-item-center img-fluid img-responsive logo equitable-part-fbn"
                                />

                                <img
                                    src={require(`../../assets/images/${lang === 'en' ? 'Equitable.png' : 'Equitable-fr.png'}`)}
                                    alt="Feeling Better Now"
                                    style={{ height: 40 }}
                                    className="align-item-center img-fluid img-responsive logo ml-2 mt-1 equitable-part-logo"
                                />
                            </div>
                        </Link>
                    </li>
                </ul>
                <ul className="d-flex link-part">
                    {/* <li className="nav-item d-flex align-items-center justify-content-end">
                        <Link to="/about" className="nav-link">
                            {t("navbar.about")}
                        </Link>
                    </li> */}
                    {/* <li className="nav-item d-flex align-items-center justify-content-end">
          <a href="/#news" className="nav-link">
          {t("navbar.news")}
          </a>
        </li>
        <li className="nav-item d-flex align-items-center justify-content-end">
          <a href="/about#contact" className="nav-link">
          {t("navbar.contact")}
          </a>
        </li> */}
                    {
                        isLoggedIn ? (
                            <li
                                className="nav-item d-flex align-items-center justify-content-end"
                                tabIndex="0"
                            >
                                {loading || detailsDataLoading ? t('summaryPage.loading')
                                    : (hasClient ? (
                                        allowAssessment || inProgress ? (
                                            <Link to="/assessment" className="nav-link">
                                                {inProgress
                                                    ? t('fbnIntro.buttonText2Alt')
                                                    : t('fbnIntro.buttonText2')}
                                            </Link>
                                        ) : (
                                            // <a
                                            //     href="/"
                                            //     className="nav-link"
                                            //     onClick={(e) => {
                                            //         e.preventDefault()
                                            //         setshowAssessmentGapPopup(true)
                                            //     }}
                                            // >
                                            <Link to="/assessment" className="nav-link">
                                                {inProgress
                                                    ? t('fbnIntro.buttonText2Alt')
                                                    : t('fbnIntro.buttonText2')}
                                            </Link>
                                            // </a>
                                        )
                                    ) : (
                                        <a
                                            href="/"
                                            className="nav-link"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowClientAccessPopUp(true);
                                            }}
                                        >
                                            {inProgress
                                                ? t('fbnIntro.buttonText2Alt')
                                                : t('fbnIntro.buttonText2')}
                                        </a>
                                    ))}
                            </li>
                        ) : (
                            // <a className="" href="/about#contact">
                            <li
                                className="nav-item d-flex align-items-center justify-content-end"
                                tabIndex="0"
                            >
                                {/* <HashLink className="nav-link" to="/about#contact">
                                    {t("fbnIntro.buttonText1")}
                                </HashLink> */}
                                <a
                                    // to="/assessment"
                                    onClick={() => setshowLoginPopup(true)}
                                    className="nav-link"
                                >
                                    {t('fbnIntro.buttonText2')}
                                </a>
                            </li>
                        )
                        // </a>
                    }

                    {isLoggedIn ? (
                        <li
                            className="nav-item d-flex align-items-center justify-content-end"
                            data-toggle="modal"
                            data-target="#assessmentHistoryModal"
                            role="button"
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        >
                            {t('navbar.assessment')}
                        </li>
                    ) : null}

                    {!isLoggedIn ? <></> : education_id ? (
                        <li className="nav-item d-flex align-items-center justify-content-end">
                            <Link to={`/Education/${education_id}`} className="nav-link">
                                {t('navbar.education')}
                            </Link>
                        </li>
                    ) : (
                        <li className="nav-item d-flex align-items-center justify-content-end">
                            <Link to="/Education" className="nav-link">
                                {t('navbar.education')}
                            </Link>
                        </li>
                    )}

                    {/* <li className="nav-item d-flex align-items-center justify-content-end">
                        <Link to="/profile" className="nav-link">
                            {t("navbar.profile")}
                        </Link>
                    </li> */}
                    {!isLoggedIn
                        && (
                            <li
                                className="nav-item d-flex align-items-center justify-content-end"
                                tabIndex="0"
                            >
                                <div
                                    className="nav-link"
                                    onClick={() => {
                                        i18n.changeLanguage(lang === 'en' ? 'fr' : 'en');
                                        setLang(lang === 'en' ? 'fr' : 'en');
                                        store.set('lang', lang === 'en' ? 'fr' : 'en');
                                    }}
                                    style={{ boxShadow: 'none' }}
                                >
                                    {lang ? (lang === 'en' ? 'French' : 'English') : 'English'}
                                </div>
                            </li>
                        )}

                    {/* <li className="nav-item d-flex align-items-center justify-content-center">
          {isLoggedIn ? (
            <div className="btnmr-1">
              <button className="btn homeNavBtn" onClick={logOut}>
                {t("navbar.logout")}
              </button>
            </div>
          ) : (
            <button
              className="btn homeNavBtn"
              onClick={() => window.open(getLoginURL(getNonce()), "_self")}
            >
              {t("fbnIntro.signinbutton")}
            </button>
          )}
        </li> */}

                    {!isLoggedIn ? (
                        <li className="nav-item d-flex align-items-center justify-content-center">
                            <button
                                style={{ padding: '16px 15px' }}
                                className="btn homeNavBtn"
                                onClick={() => setshowLoginPopup(true)}
                            >
                                {t('fbnIntro.signinbutton')}
                            </button>
                        </li>
                    ) : (
                        <></>
                    )}
                    <button
                        className="navbar-toggler "
                        type="button"
                        id="open-mobile-menu"
                        onClick={handleMenuClick}
                    >
                        <span />
                        <span />
                        <span />
                    </button>

                    {/* <!-- Mobile Menu Code Start --> */}
                    <div className="only-show-in-mobile">
                        <div className="nav-header-link">
                            <div className="nav-title custom-sidebarHeader">

                                <div className="logo-part">
                                    <img
                                        src={require('../../assets/images/FeelingBetterNowLogo.svg').default}
                                        alt="Feeling Better Now"
                                        style={{ height: 33 }}
                                        className="align-item-center img-fluid img-responsive logo f-logo"
                                    />
                                </div>
                                <div className="mobile-close-icon d-flex justify-content-end" onClick={handleMenuClick}>
                                    <img src={require('../../assets/images/mobile-close.svg').default} className="close" />
                                </div>
                                {/* {isLoggedIn && (
                                    <div className="nav-header-part">
                                        <div className="coins-part d-flex">
                                            <img src={require("../../assets/images/header-coins.svg")} className="coin" />
                                            <div className="coins-content">
                                                <p>{t("commonHeader.myCoin")}</p>
                                                <strong>C 1600</strong>
                                            </div>
                                        </div>
                                    </div>
                                )} */}
                            </div>

                        </div>
                        <ul>
                            <li>
                                <Link to="/about" className="nav-link">
                                    {t('navbar.about')}
                                </Link>
                            </li>
                            {
                                isLoggedIn ? (
                                    <li
                                        className="nav-item d-flex align-items-center justify-content-end"
                                        tabIndex="0"
                                    >
                                        {loading || detailsDataLoading ? t('summaryPage.loading')
                                            : (hasClient ? (
                                                allowAssessment || inProgress ? (
                                                    <Link to="/assessment" className="nav-link">
                                                        {inProgress
                                                            ? t('fbnIntro.buttonText2Alt')
                                                            : t('fbnIntro.buttonText2')}
                                                    </Link>
                                                ) : (
                                                    <Link to="/assessment" className="nav-link">
                                                        {/* <a
                                                            href="/"
                                                            className="nav-link"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setshowAssessmentGapPopup(true)
                                                            }}
                                                        > */}
                                                        {inProgress
                                                            ? t('fbnIntro.buttonText2Alt')
                                                            : t('fbnIntro.buttonText2')}
                                                        {/* </a> */}
                                                    </Link>
                                                )
                                            ) : (
                                                <a
                                                    href="/"
                                                    className="nav-link"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowClientAccessPopUp(true);
                                                    }}
                                                >
                                                    {inProgress
                                                        ? t('fbnIntro.buttonText2Alt')
                                                        : t('fbnIntro.buttonText2')}
                                                </a>
                                            ))}
                                    </li>
                                ) : (
                                    // <a className="" href="/about#contact">
                                    <li
                                        className="nav-item d-flex align-items-center justify-content-end"
                                        tabIndex="0"
                                    >
                                        {/* <HashLink className="nav-link" to="/about#contact">
                                        {t("fbnIntro.buttonText1")}
                                    </HashLink> */}
                                        <a
                                            // to="/assessment"
                                            onClick={() => setshowLoginPopup(true)}
                                            className="nav-link"
                                        >
                                            {t('fbnIntro.buttonText2')}
                                        </a>
                                    </li>
                                )
                                // </a>
                            }
                            {fromPAP || fromSummary
                                && (
                                    <li className="nav-item d-flex align-items-center justify-content-end" onClick={() => { setRun(true); handleMenuClick(); }}>
                                        <a className="nav-link">{t('commonHeader.startTutorial')}</a>
                                    </li>
                                )}

                            {fromGoal
                                && (
                                    <li className="nav-item d-flex align-items-center justify-content-end" onClick={() => { setRun(true); handleMenuClick(); }}>
                                        <a className="nav-link">{t('commonHeader.startTutorial')}</a>
                                    </li>
                                )}
                            {fromGoal
                                && (
                                    <li className="nav-item d-flex align-items-center justify-content-end" onClick={showTooltipOnhover}>
                                        <a className="nav-link">{isActive ? 'Hide tooltips' : 'Show tooltips'}</a>
                                    </li>
                                )}
                            {detailsData && !assessmentDisordersLoading && historyData
                                && (
                                    <li className="nav-item d-flex align-items-center justify-content-end">
                                        <a className="nav-link" onClick={handleDownloadMapClick}>Medical Action Plan</a>
                                    </li>
                                )}

                            {/* <li><Link to="/news" className="nav-link">{t("navbar.news")}</Link></li>
                                        <li><Link to="/contact" className="nav-link">{t("navbar.contact")}</Link></li> */}
                            {isLoggedIn ? (
                                <li
                                    className="nav-item d-flex align-items-center justify-content-end"
                                >
                                    <a
                                        data-toggle="modal"
                                        data-target="#assessmentHistoryModal"
                                        role="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setModalOpen(true);
                                        }}
                                        className="nav-link"
                                    >
                                        {t('navbar.assessment')}
                                    </a>
                                </li>
                            ) : null}
                            {isLoggedIn && (
                                <li>
                                    <Link to="/profile" className="nav-link">
                                        {t('navbar.profile')}
                                    </Link>
                                </li>
                            )}

                            {education_id ? (
                                <li className="nav-item d-flex align-items-center justify-content-end">
                                    <Link to={`/Education/${education_id}`} className="nav-link">
                                        {t('navbar.education')}
                                    </Link>
                                </li>
                            ) : (
                                isLoggedIn && (
                                    <li className="nav-item d-flex align-items-center justify-content-end">
                                        <Link to="/Education" className="nav-link">
                                            {t('navbar.education')}
                                        </Link>
                                    </li>
                                )
                            )}

                            {/* {false && isLoggedIn && (
                                <li className="nav-item d-flex align-items-center justify-content-end">
                                    <Link className="nav-link">
                                        My Achievement
                                    </Link>
                                </li>
                            )} */}
                            {/* <li className="nav-item d-flex align-items-center justify-content-end">
                        <Link  className="nav-link">
                          My Profile
                        </Link>
                    </li> */}
                            <li
                                className="nav-item d-flex align-items-center justify-content-end"
                                tabIndex="0"
                            >
                                <a
                                    className="nav-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        i18n.changeLanguage(lang === 'en' ? 'fr' : 'en');
                                        setLang(lang === 'en' ? 'fr' : 'en');
                                        store.set('lang', lang === 'en' ? 'fr' : 'en');
                                    }}
                                    style={{ boxShadow: 'none' }}
                                >
                                    {lang ? (lang === 'en' ? 'French' : 'English') : 'English'}
                                </a>
                            </li>
                            {isLoggedIn
                                && (
                                    <li className="nav-item d-flex align-items-center justify-content-end">
                                        <a onClick={logOut} className="nav-link">
                                            {t('navbar.logout')}
                                        </a>
                                    </li>
                                )}

                            {!isLoggedIn && (
                                <li className="nav-item d-flex align-items-center justify-content-end">
                                    <a
                                        className="nav-link"
                                        onClick={() => setshowLoginPopup(true)}
                                    >
                                        {t('fbnIntro.signinbutton')}
                                    </a>
                                </li>
                            )}
                        </ul>
                        {/* <div className="btnmr-1">
                  <button
                    className="btn nv-mobile-btn mb-1"
                    onClick={() => {
                      i18n.changeLanguage(lang === "en" ? "fr" : "en")
                      setLang(lang === "en" ? "fr" : "en")
                      store.set("lang", lang === "en" ? "fr" : "en")
                    }}
                  >
                    {lang ? (lang === "en" ? "French" : "English") : "English"}
                  </button>
              </div>
              {isLoggedIn ? (
                <div className="btnmr-1">
                  <button className="btn nv-mobile-btn" onClick={logOut}>
                    {t("navbar.logout")}
                  </button>
                </div>
              ) : (
                <button
                  className="btn nv-mobile-btn"
                  onClick={() => window.open(getLoginURL(getNonce()), "_self")}
                >
                  {t("fbnIntro.signinbutton")}
                </button>
              )} */}

                    </div>

                    {/* <!-- Mobile Menu Code End --> */}
                </ul>
                {isLoggedIn && (
                    <ul className="logout-part">
                        {/* <li
                            className="nav-item d-flex align-items-center justify-content-end notification"
                            tabIndex="0"
                        >
                            <img
                                src={require("../../assets/images/header-n-bell.svg")} />
                        </li> */}
                        <li
                            className="nav-item d-flex align-items-center justify-content-end name"
                            tabIndex="0"
                            data-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <div className="name-round">
                                <p className="name">{(email && email[0] + email[1]) || 'FBN'}</p>
                            </div>
                        </li>
                        <li
                            className="nav-item d-flex align-items-center justify-content-end"
                            tabIndex="0"
                        >
                            <div className="dropdown symptom  custom-header-dropdown">
                                <a className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" style={{ width: '100%' }}>
                                    {/* email */}
                                    <i className="fas fa-chevron-down ml-0" />
                                </a>
                                <div className="dropdown-menu custom-menu">
                                    <ul>
                                        {/* <li>
                                            <div className="coins-part d-flex">
                                                <img src={require("../../assets/images/header-coins.svg")} className="coin" />
                                                <div className="coins-content">
                                                    <p>{t("commonHeader.myCoin")}</p>
                                                    <strong>C 1600</strong>
                                                </div>
                                            </div>
                                        </li> */}
                                        {fromPAP || fromSummary
                                            && (
                                                <li onClick={() => setRun(true)}>
                                                    {t('commonHeader.startTutorial')}
                                                </li>
                                            )}
                                        {detailsData && !assessmentDisordersLoading && historyData
                                            && (
                                                <li className="nav-link">
                                                    <div onClick={handleDownloadMapClick}>
                                                        {' '}
                                                        {t('navbar.map')}
                                                    </div>
                                                </li>
                                            )}
                                        {fromGoal
                                            && (
                                                <li className="nav-link" onClick={() => { setRun(true); }}>
                                                    <a>{t('commonHeader.startTutorial')}</a>
                                                </li>
                                            )}
                                        {fromGoal
                                            && (
                                                <li className="nav-link" onClick={showTooltipOnhover}>
                                                    <a>{isActive ? 'Hide tooltips' : 'Show tooltips'}</a>
                                                </li>
                                            )}

                                        {/* <Link to={`/goal`}>
                                            <li>
                                                {t("summaryPage.cardSection.myplanGoals")}
                                            </li>
                                        </Link> */}
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                i18n.changeLanguage(lang === 'en' ? 'fr' : 'en');
                                                setLang(lang === 'en' ? 'fr' : 'en');
                                                store.set('lang', lang === 'en' ? 'fr' : 'en');
                                            }}
                                            style={{ boxShadow: 'none' }}
                                        >
                                            <li>
                                                {lang ? (lang === 'en' ? 'French' : 'English') : 'English'}
                                            </li>
                                        </a>

                                        {/* {education_id ? (
                                            <li className="">
                                                <Link to={`/Education/${education_id}`} className="">
                                                    {t("navbar.education")}
                                                </Link>
                                            </li>
                                        ) : (
                                            <li className="">
                                                <Link to={`/Education`} className="">
                                                    {t("navbar.education")}
                                                </Link>
                                            </li>
                                        )} */}

                                        {/* {false && <li className="active">My Achivement</li>} */}
                                        <Link to="/profile">
                                            <li>
                                                {t('navbar.profile')}
                                            </li>
                                        </Link>
                                        <a onClick={logOut}>
                                            <li>
                                                {t('navbar.logout')}
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        {/* {isLoggedIn ? (
                <div className="btnmr-1">
                <button className="btn homeNavBtn" onClick={logOut}>
                    {t("navbar.logout")}
                </button>
                </div>
            ) : (
                <button
                className="btn homeNavBtn"
                onClick={() => window.open(getLoginURL(getNonce()), "_self")}
                >
                {t("fbnIntro.signinbutton")}
                </button>
            )} */}
                    </ul>
                )}
            </div>

            <AssessmentsHistoryWrapper
                open={modalOpen}
                toggle={() => {
                    setModalOpen(!modalOpen);
                }}
                close={() => {
                    setModalOpen(false);
                }}
            />
        </>
    );
}

export default withTranslation()(Header);
